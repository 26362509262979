import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Row, Col} from 'reactstrap';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from "pages/AboutMe/Footer";


const ContactUs = (props) => {
    document.title = "Contact Us - Virtual Skills Platform";


    const [isLoading, setLoading] = useState(true)

    useEffect(() => {

    })


    useEffect(() => {
        async function fetchData() {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   

            setLoading(false)


        }
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    return (
        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>

                   
                   
                    {/* <!-- VSP --> */}
            <section class="vsp_sec">
                <div class="container">
                    <h2 class="global_title">Contact Us</h2>
                    
                    <div class="row justify-content-center">
                        <div class="col-md-6 col-sm-12">
                            <div class="vsp_block">
                                <h3 class="counter"><img src="/images/phone_rounded_ic.png" style={{ height : 50, marginRight : 10  }} alt="" />+44 7777 8888 88</h3>
                                {/* <p>UK </p> */}
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                            <div class="vsp_block">
                            <h3 class="counter"><img src="/images/email_rounded_ic.png" style={{ height : 50, marginRight : 10  }} alt="" />info@virtualskillshub.co.uk</h3>
                                {/* <p>UK</p> */}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>

                    <Footer />
                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(ContactUs);