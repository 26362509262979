import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import {
  postProdLogin,
  postProdJwtLogin,
  postProdDirectJwtLogin,
} from "../../../helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, type, responseHandler, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postProdJwtLogin, {
        email: user.email,
        password: user.password,
      });
      // console.log("RES SAGA LOGIN ",response)

      if(response.status) {
        localStorage.setItem("authUser", JSON.stringify(response));
        responseHandler({ type, status: true, error: "", loading: false, modalState: false });
        yield put(loginSuccess(response));
      } else {
        responseHandler({ type, status: false, error: response.error, loading: false, modalState: true });
      }

      
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend();
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // console.log("SOCIAL LOGOUT SAGA ",fireBaseBackend)
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    // history('/login');
    history('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function normalizeResponse(response) {
  // If the response has Sequelize-like methods, extract the data from dataValues
  if (response && typeof response.dataValues === 'function') {
      return response.dataValues();
  }

  // If the response is in another known format, handle accordingly
  if (response && response.dataValues) {
      return response.dataValues;  // If it's an object with dataValues as a key
  }

  // Otherwise, assume it's a regular JSON object
  return response;
}

function* socialLogin({ payload: { type, responseHandler, history } }) {
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      // console.log("SOCIAL LOGIN SAGA ",fireBaseBackend)
      
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      // console.log("RES ",response)

      if(typeof response == "object") {
        const Jwtresponse = yield call(postProdDirectJwtLogin, {
          socialResponse: response,
        });
        // console.log("RES SAGA ",response,Jwtresponse)

        if (response) {
          history("/");
        } else {
          // history("/login");
          history("/");
        }
        // console.log("CALLING CLOSE",normalizeResponse(Jwtresponse))
        localStorage.setItem("authUser", JSON.stringify(normalizeResponse(Jwtresponse)));
        responseHandler({ type, status: true, error: "", loading: false, modalState: false });
        yield put(loginSuccess(Jwtresponse));
      } else {
        responseHandler({ type, status: false, error: response, loading: false, modalState: true });
      }

    // }
    // const response = yield call(fireBaseBackend.socialLoginUser, type);
    // if(response)
    // history("/");
  } catch (error) {
    responseHandler({ type, status: false, error: error, loading: false, modalState: true });
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
