import React, { useEffect, useState } from "react"
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from "pages/AboutMe/Footer";


const PrivacyPolicy = (props) => {
    document.title = "Privacy Policy - Virtual Skills Platform";


    const [isLoading, setLoading] = useState(true)

    useEffect(() => {

    })


    useEffect(() => {
        async function fetchData() {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   

            setLoading(false)


        }
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    return (
        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>

                    {/* <!-- Banner --> */}
                    <br/><br/>
                    <div class="">
                        <div class="container">
                            <h2 class="global_title text-start">Privacy Policy</h2><br/>
                          
                        </div>
                    </div>
                    <br/>
                    <section class="">
                        <div class="container">
                            <h2 class="text-start">Privacy notice</h2>
                            <div >
                                <p>
                                    We and our partners store and/or access information on a device, such as cookies, and process personal data, such as unique identifiers, and standard information sent by a device for personalised ads and content, ad and content measurement and audience insights, as well as to develop and improve products.
                              </p>
                                <p>
                                    Please note some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only.
                              </p>
                                <p>
                                    You can request to receive information or an exported file of any personal data we hold about you including any data you may have provided us you can also request that we erase any personal data we hold about you however this excludes any data we are obliged to keep for administrative, legal or security purposes.
                              </p>
                            </div>
                        </div>
                    </section>
                    <br />
                    <br />

                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(PrivacyPolicy);