import { getFooterCourses, redirectToCourse } from "helpers/functions";
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {

  const [categoryList, setCategoryList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const getCategoryList = () => {
    const list = localStorage.getItem("categoryList");
    if (list) return JSON.parse(list);
    return [];
  };

  const getCourseList = () => {
    const list = getFooterCourses();
    
    if (list) return list;
    return [];
  };

  const initialize = () => {
    // const categoryListState = useSelector((state) => {
    //   console.log("S ",state?.Profile?.category_list || [])
  
    //   return state?.Profile?.category_list || []
    // })

    

    setCategoryList(getCategoryList())
    let c = getCourseList();
    
    setCourseList(c)
  };


  useEffect(() => {
    initialize();

    
  }, []);
  

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          {/* <Row>
            <Col sm={12}>
              © {new Date().getFullYear()} Virtual Skills Platform
            </Col>
          </Row> */}
          
          <footer className="footer_sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  {/* <figure><img src="images/foot-logo.svg" alt="" /></figure> */}
                  <figure><img src="images/logo-foot.svg" alt="" /></figure>
                  {/* <p>The Boscombe locally based app to help you designing your long-term professional career and choose your next training programme to expand and improve your profile.</p> */}
                  <p>The digital skills app for Bournemouth, Christchurch and Poole - helping you design your career and expand your skillset.</p>
                  <ul className="social_links">
                    <li><a href="#"><i className="fa fa-facebook-f" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    <div className="col-xl-4 col-sm-3">
                      <h3>Company</h3>
                      <ul className="foot_links">
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contactus">Contact Us</Link></li>
                        <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                        <li><Link to="/termscondition">Terms & Condition</Link></li>
                        {/* <li><a href="#">Career</a></li> */}
                        {/* <li><a href="#">Culture</a></li> */}
                        {/* <li><a href="#">Blog</a></li> */}
                        {/* <li><a href="#">Help Center</a></li> */}
                        {/* <li><a href="#">Support</a></li> */}
                        {/* <li><a href="#">Legal</a></li> */}
                      </ul>
                    </div>
                    <div className="col-xxl-5 col-xl-4 col-sm-5">
                      <h3>Explore Courses</h3>
                      <ul className="foot_links">
                        {/* <li><a href="#">UI/UX Designer</a></li> */}
                        {courseList.length > 0 && courseList.map((course,index) => {
                            return (<li><a href="#" className="clamp-text-1" onClick={(e) => {
                              e.preventDefault()
                              redirectToCourse(course)}} >{course.title}</a></li>)
                          })
                        }
                        {/* <li><a href={`/course-list?c=`} className="more linkanimated">View All Courses</a></li> */}
                      </ul>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-sm-4">
                      <h3>Top Categories</h3>
                      <ul className="foot_links">
                      {categoryList.length > 0 && categoryList.map((category,index) => {
                          return (<li><a href={`/course-list?c=${category.category_id}`}>{category.category_name}</a></li>)
                        })
                      }
                        {/* <li><a href="#">Development</a></li>
                        <li><a href="#">Marketing</a></li>
                        <li><a href="#">IT & Software</a></li>
                        <li><a href="#">Personal Development</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Photography</a></li> */}
                        {/* <li><a href={`/course-list?c=`} className="more linkanimated">View All Categories</a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright"><p>Copyright © {new Date().getFullYear()} Virtual Skills Platform <span>|</span> All Rights Reserved <span>|</span> <Link to="/termscondition">Terms and Conditions</Link> <span>|</span> <Link to="/privacypolicy">Privacy Policy</Link></p></div>
            </div>
            <a href="#" id="return-to-top"><i className="fa fa-angle-up"></i></a>
          </footer>

        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
