import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
// import { useHistory } from 'history';
import { calculateDiscount, formatOption, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS




import Header from "../AboutMe/Header";
import Footer from "../AboutMe/Footer";

const MyTrainingDetail = (props) => {
  document.title = "My Training Detail - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)

  const [formValues, setFormValues] = useState({
    search: '',
  })


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
        search: Yup.string().required("Please enter search query"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);
      
      console.log("SUBMITTED")
      setTimeout(() => {
        setLoading(false)
        setEditMode(false)
      },3000)
    //   post(url.UPDATE_BADGE, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
    //     let message = response?.response || "";
    //     let responseData = response?.data || [];
        
    //     window.alert("Success!\n"+message)
    //     window.history.go(-1);
    //     setLoading(false)

    //   }).catch((e) => {
    //     window.alert("Sorry!\n"+e)
    //   }).finally(() => {
    //     setLoading(false)
    //   });   
      
     
    },
  });

  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 




  return (
    <React.Fragment>
    {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
    { 
      <>
                    
                
                <Header />


            <section className="about_sec">
                <Form className="needs-validation"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}> 
                    <div className="container">
                      
                        
                    {/* <!-- Experience --> */}
                    <div class="program_info detail">
                        <ul class="breadcrumb">
                          <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                          <li><a href="#" title="About Me">My Training</a></li>
                          <li>My Training Programs</li>
                        </ul>
                        
                        <div class="row">
                          <div class="col-lg-3 col-md-12">
                            <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button"data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  <i><img src="/images/scrume_ic_1.svg" alt="" /></i>Scrum Master
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                  <ul>
                                  <li><a href="#">Scrum Basics</a></li>
                                  <li><a href="#">Scrum Advance</a></li>
                                  <li><a href="#">Scrum Theory</a></li>
                                  <li><a href="#">Scrum Project</a></li>
                                  </ul>
                                </div>
                                </div>
                              </div>
                            </div>

                            <a href="#" class="fullstack">
                              <i><img src="/images/scrume_ic_1.svg" alt="" /></i>Sr. Java Fullstack Development Manager
                            </a>
                          </div>

                          <div class="col-lg-9 col-md-12">
                            <div class="scrume_block">
                              <div class="row">
                                <div class="col-md-8">
                                  <div class="title">
                                    <i><img src="/images/paython_logo_1.png" alt="" /></i>
                                    <h4>Scrum Master Advance Course <small>Bournmath University</small></h4>
                                  </div>
                                  <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.  Aenean massa. Cum sociis natoque penatibus et magnis.</p>
                                  <ul class="python_list">
                                    <li>Introduction </li>
                                    <li>Installation </li>
                                    <li>Getting started </li>
                                  </ul>
                                </div>

                                <div class="col-md-4">
                                  <div class="label">Overall Progress</div>
                                  <div class="progress_info">
                                    <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                      <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "10%"}}></div>
                                    </div>
                                    <div class="progress_time">
                                      <small>10%</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="course_info">
                              <h2>Course content</h2>
                              <div class="course_in">
                                <h3>Introduction of Scrum Flow</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget. </p>
                                <div class="row">
                                  <div class="col-md-4 col-sm-6">
                                    <div class="label">Progress</div>
                                    <div class="progress_info">
                                      <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "100%"}}></div>
                                      </div>
                                      <div class="progress_time">
                                        <small>100%</small>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-8 col-sm-6 text-end">
                                    <a href="#" class="btns green">Completed</a>
                                  </div>
                                </div>
                              </div>

                              <div class="course_in">
                                <h3>What is Agile?</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget. </p>
                                <div class="row">
                                  <div class="col-md-4 col-sm-6">
                                    <div class="label">Progress</div>
                                    <div class="progress_info">
                                      <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "60%"}}></div>
                                      </div>
                                      <div class="progress_time">
                                        <small>60%</small>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-8 col-sm-6 text-end">
                                    <a href="#" class="btns blue">In-Progress</a>
                                  </div>
                                </div>
                              </div>

                              <div class="course_in">
                                <h3>Scrum Artifacts</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget. </p>
                                <div class="row">
                                  <div class="col-md-4 col-sm-6">
                                    <div class="label">Progress</div>
                                    <div class="progress_info">
                                      <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "0%"}}></div>
                                      </div>
                                      <div class="progress_time">
                                        <small>0%</small>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-8 col-sm-6 text-end">
                                    <a href="#" class="btns blue">In-Progress</a>
                                  </div>
                                </div>
                              </div>

                              <div class="course_in">
                                <h3>The Scrum Team</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget. </p>
                                <div class="row">
                                  <div class="col-md-4 col-sm-6">
                                    <div class="label">Progress</div>
                                    <div class="progress_info">
                                      <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "0%"}}></div>
                                      </div>
                                      <div class="progress_time">
                                        <small>0%</small>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-8 col-sm-6 text-end">
                                    <a href="#" class="btns blue">In-Progress</a>
                                  </div>
                                </div>
                              </div>

                              <div class="course_in">
                                <h3>Scaling Scrum</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget. </p>
                                <div class="row">
                                  <div class="col-md-4 col-sm-6">
                                    <div class="label">Progress</div>
                                    <div class="progress_info">
                                      <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "0%"}}></div>
                                      </div>
                                      <div class="progress_time">
                                        <small>0%</small>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-8 col-sm-6 text-end">
                                    <a href="#" class="btns blue">In-Progress</a>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                </Form>
            </section>

            <Footer />
                
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyTrainingDetail);