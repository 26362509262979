import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, randomNumber, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";

const MySkill = (props) => {
    document.title = "My Skills - Virtual Skills Platform";

    const animatedComponents = makeAnimated();

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [isEditMode, setEditMode] = useState(false)
    const [defaultQuestions, setDefaultQuestions] = useState([
        { question : "I have access to computers and other media devices", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I do have a good internet connection", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have studied before through online learning or adult learning", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have recently online learned, including undertaking an online short course", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
    ])

    const QUALIFICATION = [
        { label: "Master's Degree MA, MSc", value: "1" },
        { label: "Master's Degree BA, BSc", value: "2" },
        { label: "More", value: "3" }
    ]

    const DEFAULTANSWERS = [
        { label: "Unsure", value: "Unsure" },
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
    ]

    const handleQualificationSelect = (selectedOptions) => {
        validation.setFieldValue('qualification', selectedOptions);
    };

    const ONLINETrainings = [
        { label: "Mixed F2F/online", value: "1" },
        { label: "Mixed B2B/online ", value: "2" },
        { label: "More", value: "3" }
    ]
    const handleOnlineTrainingSelect = (selectedOptions) => {
        validation.setFieldValue('OnlineTraining', selectedOptions);
    };

    const DESIREDTraining = [
        { label: "Mainly practical, I think I already hold the theory", value: "1" },
        { label: "Mainly party, I think I already", value: "2" },
        { label: "More", value: "3" }
    ]
    const handleDesiredTrainingSelect = (selectedOptions) => {
        validation.setFieldValue('DesiredTraining', selectedOptions);
    };

    const LOWWORKLOAD = [
        { label: "I want to dedicate a high amount of time on a regular basis", value: "1" },
        { label: "I want to dedicate a high amount", value: "2" },
        { label: "More", value: "3" }
    ]
    const handleLowWorkloadSelect = (selectedOptions) => {
        validation.setFieldValue('LowWorkload', selectedOptions);
    };

    const IMMEDIATEAVA = [
        { label: "Searching for a mid/long-term training", value: "1" },
        { label: "possible for a mid/long-term full", value: "2" },
        { label: "More", value: "3" }
    ]


    const [formValues, setFormValues] = useState({
        qualification: "Master's Degree MA, MSc",
        OnlineTraining: "Mixed F2F/online",
        DesiredTraining: "Mainly practical, I think I already hold the theory",
        LowWorkload: "I want to dedicate a high amount of time on a regular basis",
        ImmediateAvailability: "Searching for a mid/long-term training",
    })



    const validation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: formValues,
        validationSchema: Yup.object({
            // question_id: Yup.object().required("Please enter question"),
            // qualification: Yup.string().required("Please enter qualification"),
        }),
        onSubmit: async (values) => {
            // Handle form submission logic here

            setLoading(true);

            console.log("SUBMITTED")
            setTimeout(() => {
                setLoading(false)
                setEditMode(false)
            }, 3000)
            //   post(url.UPDATE_BADGE, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {


            //     let message = response?.response || "";
            //     let responseData = response?.data || [];

            //     window.alert("Success!\n"+message)
            //     window.history.go(-1);
            //     setLoading(false)

            //   }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            //   }).finally(() => {
            //     setLoading(false)
            //   });   


        },
    });



    useEffect(() => {

    })


    useEffect(() => {
        async function fetchData() {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   

            setLoading(false)


        }

        
        async function loadDefaultQuestionsAnswers() {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   
            

            

            // let defaultFormValues = formValues

            let questionObj = {}


            defaultQuestions.map((data,i) => {
                questionObj[data.question_key] = data.defaultAnswer
            })

            console.log("Q ",questionObj)
            setFormValues({ ...formValues, ...questionObj })


        }


        // initializeSelect()
        loadDefaultQuestionsAnswers();
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    const handleImmediateAvailabilitySelect = (selectedOptions) => {
        validation.setFieldValue('ImmediateAvailability', selectedOptions);
    };

    const handleAnswerSelect = (selectedOptions,key) => {
        validation.setFieldValue(key, selectedOptions);
    };


    return (
        <React.Fragment>
            {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
            {
                <>


                    <Header />


                    <section className="about_sec">
                        <Form className="needs-validation" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <div className="container">


                                {/* <!-- About --> */}
                                <div className="about_main">
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                        <li><a href="#" title="About Me">About Me</a></li>
                                        <li>My Skills</li>
                                    </ul>
                                    <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                        <div className="col-md-auto col-sm-12">
                                            <h3>My Information</h3>
                                        </div>
                                        <div className="col-md-auto col-sm-12 text-end">
                                            {/* <a href="about_me_edit.html" className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Profile</a> */}
                                            {isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                            {/* <Button className="btns green" color="primary" type="submit">
                                        Save
                                    </Button> */}
                                            {!isEditMode && <button type="button" onClick={() => setEditMode(true)} className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Skills</button>}
                                        </div>
                                    </div>
                                    <div className="about_block">
                                        {/* <div className="row">
                                            <div className="col-lg-6 col-md-4 col-sm-12">
                                                <div className="input_group">
                                              

                                                    <Label>My Qualification</Label>
                                                    {
                                                        isEditMode ?
                                                            <>
                                                                <Select
                                                                    value={validation.values.qualification}
                                                                    isMulti={false}
                                                                    onChange={handleQualificationSelect}
                                                                    options={QUALIFICATION}
                                                                    id="qualification"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.qualification && validation.touched.qualification}
                                                                />
                                                                {validation.touched.qualification && validation.errors.qualification ? (
                                                                    <div className="invalid-feedback">{validation.errors.qualification}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5>{validation.values.qualification}</h5>
                                                    }

                                                 
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-4 col-sm-12">
                                            </div>

                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-6 col-sm-4">
                                                <div className="input_group">


                                                    <Label htmlFor="validationCustom01">Prefer Online Training</Label>
                                                    {
                                                        isEditMode ?
                                                            <>
                                                                <Select
                                                                    value={validation.values.OnlineTraining}
                                                                    isMulti={false}
                                                                    onChange={handleOnlineTrainingSelect}
                                                                    options={ONLINETrainings}
                                                                    id="OnlineTraining"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.OnlineTraining && validation.touched.OnlineTraining}
                                                                />

                                                                {validation.touched.OnlineTraining && validation.errors.OnlineTraining ? (
                                                                    <div className="invalid-feedback">{validation.errors.OnlineTraining}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5>{validation.values.OnlineTraining || ''}</h5>
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-6 col-sm-4">
                                                <div className="input_group">


                                                    <Label htmlFor="validationCustom01">Type of Desired Training</Label>
                                                    {
                                                        isEditMode ?
                                                            <>

                                                                <Select
                                                                    value={validation.values.DesiredTraining}
                                                                    isMulti={false}
                                                                    onChange={handleDesiredTrainingSelect}
                                                                    options={DESIREDTraining}
                                                                    id="DesiredTraining"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.DesiredTraining && validation.touched.DesiredTraining}
                                                                />
                                                                {validation.touched.DesiredTraining && validation.errors.DesiredTraining ? (
                                                                    <div className="invalid-feedback">{validation.errors.DesiredTraining}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5>{validation.values.DesiredTraining || ''}</h5>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-4">
                                                <div className="input_group">


                                                    <Label htmlFor="validationCustom01">Low Workload</Label>
                                                    {
                                                        isEditMode ?
                                                            <>
                                                                <Select
                                                                    value={validation.values.LowWorkload}
                                                                    isMulti={false}
                                                                    onChange={handleLowWorkloadSelect}
                                                                    options={LOWWORKLOAD}
                                                                    id="LowWorkload"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.LowWorkload && validation.touched.LowWorkload}
                                                                />
                                                                {validation.touched.LowWorkload && validation.errors.LowWorkload ? (
                                                                    <div className="invalid-feedback">{validation.errors.LowWorkload}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5>{validation.values.LowWorkload || ''}</h5>
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-6 col-sm-4">
                                                <div className="input_group">


                                                    <Label htmlFor="validationCustom01">Immediate Availability</Label>
                                                    {
                                                        isEditMode ?
                                                            <>

                                                                <Select
                                                                    value={validation.values.ImmediateAvailability}
                                                                    isMulti={false}
                                                                    onChange={handleImmediateAvailabilitySelect}
                                                                    options={IMMEDIATEAVA}
                                                                    id="ImmediateAvailability"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.ImmediateAvailability && validation.touched.ImmediateAvailability}
                                                                />
                                                                {validation.touched.ImmediateAvailability && validation.errors.ImmediateAvailability ? (
                                                                    <div className="invalid-feedback">{validation.errors.ImmediateAvailability}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5>{validation.values.ImmediateAvailability || ''}</h5>
                                                    }

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Form>
                        <div className="container">
                            <br/>
                            <h4>Other Information</h4>
                            <br/>


                            <div className="row">
                                { defaultQuestions.map((data,i) => {


                                    return <div key={i} className="col-md-6">
                                    <div className="information_item">
                                        <div className="col-md-9">
                                            <p>{data.question}</p>
                                        </div>
                                        <div className="col-md-2">
                                            {
                                                isEditMode ?
                                                    <>
                                                        <Select
                                                            value={validation.values[data.question_key]}
                                                            isMulti={false}
                                                            onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,data.question_key) }
                                                            options={DEFAULTANSWERS}
                                                            id={data.question_key}
                                                            className="removeborder"
                                                            classNamePrefix="selectpicker"
                                                            isValid={!validation.errors[data.question_key] && validation.touched[data.question_key]}
                                                        />
                                                        {validation.touched[data.question_key] && validation.errors[data.question_key] ? (
                                                            <div className="invalid-feedback">{validation.errors[data.question_key]}</div>
                                                        ) : null}
                                                    </>
                                                    :
                                                    <h5 className="text-center">{validation.values?.[data.question_key]?.value || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                </div>

                                }) }
                            </div>

                            

                        </div>
                    </section>


                    <Footer />
                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(MySkill);