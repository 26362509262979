import React, { useEffect, useState } from "react"
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from "pages/AboutMe/Footer";


const TermsCondition = (props) => {
    document.title = "Terms & Condition - Virtual Skills Platform";


    const [isLoading, setLoading] = useState(true)

    useEffect(() => {

    })


    useEffect(() => {
        async function fetchData() {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   

            setLoading(false)


        }
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    return (
        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>

                    {/* <!-- Banner --> */}
                    <br/><br/>
                    <div class="">
                        <div class="container">
                            <h2 class="global_title text-start">Terms and conditions</h2><br/>
                            <h2 class="text-start">Acceptance of terms and conditions</h2>
                            <div class="">In choosing the Virtual Skills Platform app users agree to these terms and conditions.</div>
                        </div>
                    </div>
                    <br/>
                    <section class="">
                        <div class="container">
                            <h2 class="text-start">Disclaimer</h2>
                            <div >
                                <p>
                                    The contents of this Virtual Skills Platform web app are for general information purposes only. Users of the app and its contents do so entirely at their own risk. Whilst Bournemouth Christchurch and Poole Council and its developers and partners endeavour to ensure that the information on this site is correct no warranty, express or implied, is given as to its accuracy and the Council, developers and partners do not accept any liability for error or omission.
                                </p>
                                <p>
                                    Nothing on this web app constitutes specific advice and users should conduct their own analysis of any recommendations made by the Virtual Skills Platform. Specialist advice should be taken in relation to specific circumstances.
                                </p>
                                <p>
                                    Bournemouth Christchurch and Poole Council and its developers and partners shall not be liable for any damage loss or injury (including, without limitation, damage for loss of business or loss of profits) arising in contract, tort or otherwise from the use of, or inability to use this web app or any material contained in it, or from any action or decision taken as a result of this web app or any such material.
                                </p>
                                <p>
                                    Bournemouth Christchurch and Poole Council, its developers and partners are not responsible for the contents, reliability, accuracy, or sufficiency of other websites or application programming interfaces (APIs) referred or linked to by the Virtual Skills Platform web app and do not necessarily endorse the views expressed within them. Listing of a website link or API should not be taken as endorsement of any kind. We cannot guarantee the availability of these links or API's.
                                </p>
                            </div>
                        </div>
                    </section><br/>
                    {/* <section class="">
                        <div class="container">
                            <h2 class="text-start">Privacy notice</h2>
                            <div >
                                <p>
                                    We and our partners store and/or access information on a device, such as cookies, and process personal data, such as unique identifiers, and standard information sent by a device for personalised ads and content, ad and content measurement and audience insights, as well as to develop and improve products.
                              </p>
                                <p>
                                    Please note some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only.
                              </p>
                                <p>
                                    You can request to receive information or an exported file of any personal data we hold about you including any data you may have provided us you can also request that we erase any personal data we hold about you however this excludes any data we are obliged to keep for administrative, legal or security purposes.
                              </p>
                            </div>
                        </div>
                    </section> */}
                    <br />
                    <br />

                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(TermsCondition);