import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Spinners from "components/Common/Spinner"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "components/HorizontalLayout/Header";
import { get, post } from "helpers/api_helper";
import { API_BASE_URL, GET_SEARCH_RESULT, OPEN_COURSE_DETAIL, REDIRECT_TO_COURSE_URL } from "helpers/url_helper";
import { getCurrentUser, handleImageError, redirectToCourse } from "helpers/functions";

const SearchResults = (props) => {
    document.title = "Search - Virtual Skills Platform";

    // const { q, c } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [searchResults, setSearchResults] = useState([])


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const location = useLocation()

    useEffect(() => {
    fetchData()
    }, [location.key])

    const fetchData = () => {
        // console.log("FETCH ",searchParams.get("q"))
    }

    useEffect(() => {

        // console.log("QC ",searchParams.get("q"))
        // Check if the 'editMode' parameter is present
        if (searchParams) {

            let searchQ = searchParams.get("q")
            let searchC = searchParams.get("c")

            setSearchQuery(searchParams.get("q"))
            setSearchCategory(searchParams.get("c"))
            setLoading(true)
            

            async function getSearchResult() {

                let userInfo = getCurrentUser();

                let body = { 
                    c: searchC, 
                    q: searchQ 
                }

                if(userInfo != null) {
                    // console.log("USER INFO ",userInfo)
                    body.user_id = userInfo.user_id
                } else {
                    // console.log("USER INFO BLANK" )
                }

                // get(GET_SEARCH_RESULT+`?c=${searchC}&q=${searchQ}`, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {
                post(GET_SEARCH_RESULT, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

                //   console.log("FETCHED ", response)

                  let responseData = response?.data || [];

                  setSearchResults(responseData)

                  
                  setLoading(false)

                }).catch((e) => {
                  window.alert("Sorry!\n" + e)
                  setLoading(false)
                }).finally(() => {
                //   setLoading(false)
                });

            }
            getSearchResult();


            // setLoading(false)
            // setTimeout(() => {
            // },10000)
        } else {
          setLoading(false)
            // setTimeout(() => {
            //     setLoading(false)
            // },10000)
        }
    }, [searchParams]);

    const stripHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }

    // const redirectToCourse = (item) => {
        
    //     // if(searchItem.course_link) {
    //     //     return false;
    //     // }

    //     let userInfo = getCurrentUser();

    //     let body = {
    //         course_link : item?.course_link || "#",
    //         courses_id : item.courses_id
    //     }

    //     if(userInfo != null) {
    //         // console.log("USER INFO ",userInfo)
    //         body.user_id = userInfo.user_id
    //     } else {
    //         // console.log("USER INFO BLANK" )
    //     }

    //     post(REDIRECT_TO_COURSE_URL, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

    //     //   console.log("FETCHED ", response)

    //         let redirectUrl = response?.data || "#";

    //         // const { redirectUrl } = response.data;

    //         // Redirect to the new page in a new tab
    //         window.open(redirectUrl, '_blank');

            
    //         // setLoading(false)

    //     }).catch((e) => {
    //         window.alert("Sorry!\n" + e)
    //         // setLoading(false)
    //     }).finally(() => {
    //     //   setLoading(false)
    //     });
    // }

    return (
        <React.Fragment>
            {
                loading ? <><section className="about_sec"><div className="container mt-30 text-center"><Spinners  /></div></section></> :
                <>

                    <section className="container" id="category_section" style={{ backgroundColor: "white" }}>

                        <div class="job_info">
                            {/* <div class="input-group">
                                <span class="input-group-text"><img src="images/search_ic.svg" alt="" />

                                </span>
                                <input type="text" class="form-control" placeholder="Search for a Course?" />
                            </div> */}
                            <h3 className="mb-30">{searchResults.length} results for “{searchQuery}”</h3>
                            


                            {searchResults.length > 0 ? 
                                searchResults.map((searchItem,i) => {



                                    return(
                                        <div class="job_block job-block-custom">
                                            <div class="job_item">
                                                <div class="icon"><img onError={handleImageError} src={API_BASE_URL+searchItem?.institute_detail?.image || "#"} alt="" /></div>
                                                <div class="detail">
                                                    
                                                    {/* OTHER UI
                                                    <p>{searchItem?.institute_detail?.name || "-"}</p>
                                                    <h5 style={{ marginBottom : 0 }}>{searchItem?.title || ""}</h5>
                                                    <p style={{ marginTop : 10}}>Category: <small className="clamp-text">{searchItem?.category_detail?.name || "-"}</small></p>
                                                    <p style={{ marginTop : 10}}>Description: <small className="clamp-text">{stripHtmlTags(searchItem?.description || "")}</small></p> */}

                                                    <h5 style={{ marginBottom : 0 }}>{searchItem?.title || ""}</h5>
                                                    <p>{searchItem?.institute_detail?.name || "-"}</p>
                                                    <small className="clamp-text" style={{ marginTop : 10 }}>{stripHtmlTags(searchItem?.description || "")}</small>
                                                </div>
                                            </div>
                                            <div class="btn_info">
                                                {/* <a target ="_blank" href={searchItem?.course_link || "#"} class="btns gray">View Course Detail</a> */}
                                                <button type="button" onClick={() => redirectToCourse(searchItem)}  class="btns gray">View Course</button>
                                            </div>
                                        </div>
                                    )
                                })
                            :
                            <div className="info text-default">No result found</div>
                            }
                            



                        </div>


                    </section>


                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(SearchResults);








