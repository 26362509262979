import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Switch from "react-switch"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate, getCurrentDateInDefaultFormat } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality


const Offsymbol = (props) => {  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    )
  }
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    )
  }


const MyExperienceForm = (props) => {

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [isCurrentWorking, setIsCurrentWorking] = useState(false)
  const [jobRoles, setJobRoles] = useState([])

//   const [formValues, setFormValues] = useState({
//     job_role: { label: "Software Engineer", value: "Software Engineer" },
//     company_name : "BCP Council",
//     location : "BH2, Bournemouth, England",
//     start_date : new Date(),
//     end_date : new Date(),
//     job_description : "This is job description",
//     cv_id : "1",
   
//   })
  const [formValues, setFormValues] = useState({})

//   const validation = props.validation
//   const isEditMode = props.isEditMode

  const JOB_ROLES = props.job_roles

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: isCurrentWorking ? 
    Yup.object({
        company_name: Yup.string().required("Please enter company name"),
        job_role: Yup.string().required("Please enter job title"),
        location: Yup.string().required("Please enter location"),
        start_date: Yup.date().required("Please enter start date"),
        job_description: Yup.string().required("Tell us about your key responsibilities or projects"),
    })
    :
    Yup.object({
        company_name: Yup.string().required("Please enter company name"),
        job_role: Yup.string().required("Please enter job title"),
        location: Yup.string().required("Please enter location"),
        start_date: Yup.date().required("Please enter start date"),
        end_date: Yup.date().required("Please enter end date"),
        job_description: Yup.string().required("Tell us about your key responsibilities or projects"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);

      let newBody = Object.assign({}, values);

      newBody.is_current = isCurrentWorking

    //   newBody.job_role_id = newBody.job_role.value;
    //   delete newBody.job_role
      
      console.log("SUBMITTED",newBody)
      if(!newBody.id) {
        // console.log("NULL ",newBody)
        delete newBody.id;
        post(url.CREATE_EXPERIENCE, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    
            let message = response?.response || "";
            let responseData = response?.data || [];
            
            window.alert("Success!\n"+message)
            // window.history.go(-1);
            setLoading(false)
            props.setIsDisableAddBtn(false)
            
    
          }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
          }).finally(() => {
            setEditMode(false)
          }); 
      } else {
        post(url.UPDATE_EXPERIENCE, newBody, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

    
            let message = response?.response || "";
            let responseData = response?.data || [];
            
            window.alert("Success!\n"+message)
            // window.history.go(-1);
            setLoading(false)
            props.setIsDisableAddBtn(false)
    
          }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
          }).finally(() => {
            setEditMode(false)
            props.setIsDisableAddBtn(false)
          }); 
      }
      return
    //   setTimeout(() => {
    //     setLoading(false)
    //     setEditMode(false)
    //   },3000)
        
      
     
    },
  });
  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // let job_roles = props.job_roles;

        // let jbs = []

        // job_roles.map((j,i) => {
        //     jbs.push({ label : j.name, value : j.job_role_id })
        // })

        // setJobRoles(jbs)

        console.log("EXP ",props)

        let existingData = props.initFormValues;

       

        if(!existingData?.id) {
            setEditMode(true)
            setFormValues(existingData)
        } else {
            // job_role: { label: existingData?.jobRole?.name, value: existingData?.jobRole?.job_role_id },
            let obj = {
                job_role: existingData?.job_role || "",
                company_name : existingData?.company_name || "-",
                location : existingData?.location || "-",
                start_date : existingData?.start_date || new Date(),
                end_date : existingData?.end_date || new Date(),
                job_description : existingData?.job_description || "-",
                id : existingData?.id || null,
            }

            setFormValues(obj)
            setIsCurrentWorking(existingData?.is_current || false)
        }

        

        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

  const handleDropdownSelect = (selectedOptions,key) => {
    validation.setFieldValue(key, selectedOptions);
};

const onDelete = (id) => {
    props.onDelete(id)
}

  return (
    <React.Fragment>
        <>
        <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">

        <Form className="needs-validation"  onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}> 

            <div className="col-md-12 col-sm-12 text-end">
                
                { isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                {/* <Button className="btns green" color="primary" type="submit">
                    Save
                </Button> */}
                { !isEditMode && <button type="button" onClick={() => setEditMode(true) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit</button>}
                { <button type="button" onClick={() => onDelete(validation.values.id) } className="btns red ms-2">
                    <img src="/images/trash_ic.svg" style={{height : 15}} alt="" />
                    Delete
                    </button>}
            </div>
            <div className="col-md-12 col-sm-12" style={{ marginTop : 20 }}></div>
        {/* <!-- Partner --> */}
        <div className="about_block">
                                <div className="row">
                                <div className="col-md-6 col-sm-4">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Company Name</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="company_name"
                                                    placeholder="Enter company name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.company_name || ''}
                                                    invalid={validation.touched.company_name && validation.errors.company_name ? true : false}
                                                />
                                                {validation.touched.company_name && validation.errors.company_name ? (
                                                    <div className="text-danger">{validation.errors.company_name}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.company_name || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6 col-sm-4">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Job Title</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="job_role"
                                                    placeholder="Enter job title"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.job_role || ''}
                                                    invalid={validation.touched.job_role && validation.errors.job_role ? true : false}
                                                />
                                                {validation.touched.job_role && validation.errors.job_role ? (
                                                    <div className="text-danger">{validation.errors.job_role}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.job_role || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    </div>
                                   <div className="row">
                                   <div className="col-md-4 col-sm-4">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Location</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="location"
                                                    placeholder="Enter location"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.location || ''}
                                                    invalid={validation.touched.location && validation.errors.location ? true : false}
                                                />
                                                {validation.touched.location && validation.errors.location ? (
                                                    <div className="text-danger">{validation.errors.location}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.location || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                
                                 <div className="col-md-2 col-sm-2">
                                        <div className="input_group">
                                            <Label  htmlFor="validationCustom01">Start Date</Label>

                                            {
                                                isEditMode ? 
                                                <>
                                                    
                                                    <Flatpickr
                                                        name="start_date"
                                                        className={`form-control d-block ${validation.touched.start_date && validation.errors.start_date ? 'is-invalid' : ''}`}
                                                        placeholder="MM/DD/YYYY"
                                                        value={validation.values.start_date}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d/m/Y",
                                                            dateFormat: "Y-m-d"
                                                        }}
                                                        onChange={(selectedDates) => {
                                                            validation.setFieldValue('start_date', selectedDates[0]);
                                                        }}
                                                    />
                                                    {validation.touched.start_date && validation.errors.start_date ? (
                                                        <div className="text-danger">{validation.errors.start_date}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>{formatDate(validation.values?.start_date || new Date()) || ''}</h5>
                                            }
                                        </div>
                                    </div>

                                    {
                                        isEditMode && <div className="col-md-4 col-sm-4 text-center">
                                    
                                            <div className="input_group mt-20">
                                                <Label style={{ marginRight: 20 }}>Are you currently working?</Label>
                                                
                                                <Switch
                                                    uncheckedIcon={<Offsymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#0070aa"
                                                    onChange={() => {
                                                        setIsCurrentWorking(!isCurrentWorking)
                                                    }}
                                                    checked={isCurrentWorking}
                                                    />
                                            </div>
                                        </div>
                                    }

                                    <div className="col-md-2 col-sm-2">
                                        { !isCurrentWorking ? <>
                                        <div className="input_group">
                                        <Label  htmlFor="validationCustom01">End Date</Label>
                                        {
                                            isEditMode ? 
                                            <>
                                                <Flatpickr
                                                    name="end_date"
                                                    className={`form-control d-block ${validation.touched.end_date && validation.errors.end_date ? 'is-invalid' : ''}`}
                                                    placeholder="MM/DD/YYYY"
                                                    value={validation.values.end_date}
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d/m/Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                    onChange={(selectedDates) => {
                                                        validation.setFieldValue('end_date', selectedDates[0]);
                                                    }}
                                                />
                                                {validation.touched.end_date && validation.errors.end_date ? (
                                                    <div className="text-danger">{validation.errors.end_date}</div>
                                                ) : null}
                                            </>
                                            :
                                            <h5>{formatDate(validation.values?.end_date || getCurrentDateInDefaultFormat() ) || ''}</h5>
                                        }
                                        </div>
                                        </> :
                                        <div className="input_group">
                                        <Label  htmlFor="validationCustom01">End Date</Label>
                                        <h5>Currently Working</h5>
                                        </div>}
                                    </div>
                                   
                                </div>
                                <div className="row">
                              <div className="col-md-12 col-sm-12">
                                        <div className="input_group">
                                            
                                            <Label>Responsibilities</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="job_description"
                                                    placeholder="Tell us about your key responsibilities or projects"
                                                    type="textarea"
                                                    style={{ minHeight : 100 }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.job_description || ''}
                                                    invalid={validation.touched.job_description && validation.errors.job_description ? true : false}
                                                />
                                                {validation.touched.job_description && validation.errors.job_description ? (
                                                    <div className="invalid-feedback">{validation.errors.job_description}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.job_description || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                </div>
                    </div>
            </Form>
        </div>
      </>
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyExperienceForm);