
import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "../AboutMe/Header";
import Footer from "../AboutMe//Footer";
import MyQualificationForm from "../Partials/MyQualificationForm";

const MyCareer = (props) => {
  document.title = "My Career - Virtual Skills Platform";

  const animatedComponents = makeAnimated();


  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)

  const [coursesList, setCoursesList] = useState([])
  const [institutesList, setInstitutesList] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [isDisableAddBtn, setIsDisableAddBtn] = useState(false)

  const JOB_ROLES = [
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "Character Designer", value: "Character Designer" },
  ]

  

  useEffect(() => {
    async function fetchData() {

        get(url.GET_USER_QUALIFICATION, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
          let responseData = response.data
          
          let qualifications = responseData.qualifications
          let coursesList = responseData.courses
          let institutesList = responseData.institutes
          
          console.log("RES ",response,qualifications)

          setCoursesList(coursesList)
          setInstitutesList(institutesList)
          setQualifications(qualifications);

          setLoading(false)

      }).catch((e) => {
          window.alert("Sorry!\n"+e)
          setLoading(false)
      }).finally(() => {
      });   
        
        // setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



//   const handlejobroleSelect = (selectedOptions) => {
//     validation.setFieldValue('job_role_id', selectedOptions);
//   };



  return (
    <React.Fragment>
    { 
      isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  color="dark" /></div></section></> :
      <>
                    
                
                <Header />


            <section className="about_sec">
                <div className="container">
                      
                        
                        {/* <!-- About --> */}
                        <div className="about_main">
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                <li><a href="#" title="About Me">My Career</a></li>
                                <li>My Career</li>
                            </ul>
                            <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>My Career</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end">
                                   
                                    {/* { isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>} */}
                                    
					                          {/* { !isEditMode && <button type="button" onClick={() => setEditMode(true) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit My Career</button>} */}
                                </div>
                            </div>
                           
                           {/* QUALIFICATION FORM */}

                           {
                                qualifications.map((q,i) => {

                                    return(<MyQualificationForm isViewOnlyMode={true} setIsDisableAddBtn={true} onDelete={(id) => false } initFormValues={q} courses={coursesList} institutes={institutesList} />)
                                })
                            }
                        </div>

                    </div>
            </section>

            
                <Footer />
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyCareer);