import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";

import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts"
import Header from "./Header";
import Footer from "./Footer";
import { Chart } from "react-google-charts";

// @ts-expect-error

const JobHeatmap = (props) => {
  document.title = "Jobs Heatmap - Virtual Skills Platform";

  const animatedComponents = makeAnimated();


  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isLoadMoreLoading, setLoadMoreLoading] = useState(false)

  const [jobList, setJobList] = useState([])
  const [jobListMaster, setJobListMaster] = useState([])
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 450,
      type: 'heatmap',
      enableToolbar: false,
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#0170ab"],
    title: {
      text: 'Jobs Analytics',
      style: {
        margin: 0,
        padding: 0,
        fontSize: 26,
        fontWeight: 600,
        color: 'black',
      }
    },
  });

  const dataBridgwater = [
    ["Job", "Job per"],
    ["Client Manager", 1],
    ["Business Analyst", 0],
    ["Finance Business Partner", 0],
    ["Account Manager", 0]
  ];
  const PieoptionsBridgwater = {
    title: "Bridgwater",
    is3D: true,
  };

  const dataLondon = [
    ["Job", "Job per"],
    ["Client Manager", 0],
    ["Business Analyst", 2],
    ["Finance Business Partner", 1],
    ["Account Manager", 0]
  ];
  const PieoptionsLondon = {
    title: "London",
    is3D: true,
  };


  const dataBournemouth = [
    ["Job", "Job per"],
    ["Client Manager", 0],
    ["Business Analyst", 0],
    ["Finance Business Partner", 1],
    ["Account Manager", 0]
  ];
  const PieoptionsBournemouth = {
    title: "Bournemouth",
    is3D: true,
  };


  const dataLeeds = [
    ["Job", "Job per"],
    ["Client Manager", 0],
    ["Business Analyst", 0],
    ["Finance Business Partner", 0],
    ["Account Manager", 1]
  ];
  const PieoptionsLeeds = {
    title: "Leeds",
    is3D: true,
  };

  
  useEffect(() => {


  })

  const fetchData = () => {


    // `keywords=${searchQuery}&resultsToTake=${offset}&resultsToSkip=${skip}`
    post(url.GET_REED_JOB_HEATMAP, {}).then((response) => {



      let heatMapData = response.data;
      // console.log("RES ",heatMapData)

      setSeries(heatMapData)
      //setSeriesBubble(heatMapData)
      setLoading(false)
    }).catch((e) => {
      window.alert("Sorry!\n" + e)
      setLoading(false)
    }).finally(() => {
    });



  }
  function tooltipTemplate(args) {
    args.content = [
      'Job ' +
      ' : ' +
      args.xLabel +
      '<br/>' +
      'Location ' +
      ' : ' +
      args.yLabel +
      '<br/>' +
      'Count ' +
      ' : ' +
      (args.value)[0].bubbleData
      
    ];
  }

  useEffect(() => {


    fetchData();

  }, []);

  return (
    <React.Fragment>
      {
        <>


          <Header />


          <section className="about_sec">

            <div className="container">


              {/* <!-- Experience --> */}
              <div className="about_main">
                <ul className="breadcrumb">
                  <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                  <li><a href="#" title="My Jobs">My Jobs</a></li>
                  <li>Jobs Analytics</li>
                </ul>


                <div className="job_info">


                  {/* {isLoading ?
                    <div className="text-center"><Spinners /></div>
                    :
                    series.length > 0 ?
                      <ReactApexChart options={options} series={series} type="heatmap" height={500} />
                      :
                      <div className="text-center">
                        <p>There is no data to show</p>
                      </div>
                  } */}

                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <Chart
                      chartType="PieChart"
                      data={dataBridgwater}
                      options={PieoptionsBridgwater}
                      width={"100%"}
                      height={"400px"}
                    />
                      </div>
                      <div className="col-6">
                      <Chart
                      chartType="PieChart"
                      data={dataLondon}
                      options={PieoptionsLondon}
                      width={"100%"}
                      height={"400px"}
                    />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Chart
                      chartType="PieChart"
                      data={dataBournemouth}
                      options={PieoptionsBournemouth}
                      width={"100%"}
                      height={"400px"}
                    />
                      </div>
                      <div className="col-6">
                      <Chart
                      chartType="PieChart"
                      data={dataLeeds}
                      options={PieoptionsLeeds}
                      width={"100%"}
                      height={"400px"}
                    />
                      </div>
                    </div>
                  
                  </div> <br />
                  
                </div>
              </div>

            </div>
          </section>

          <Footer />

        </>
      }

    </React.Fragment>
  )
    };
export default connect(null, { setBreadcrumbItems })(JobHeatmap);