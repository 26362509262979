import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo-sm.png"
import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import logoVSP from "../../assets/images/logo.svg";
//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { getCurrentUser } from 'helpers/functions'

const Header = props => {
  const [search, setsearch] = useState(false)
  const [createmenu, setCreateMenu] = useState(false)
  

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }


  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <header className="header_sec">
        <div className="container">
          <nav className="navbar">
            <a className="navbar-brand" href="#"><img src="images/logo.svg" alt="" /></a>
            <div className="right">
              
              <div className="dropdown notifyWrapper">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="images/bell_ic.svg" alt="" />
                  <span className="blink"></span>
                </button>
                <div className="dropdown-menu">
                  <div className="notifyHeader">
                    <h4>Notification</h4>
                    <a href="#" className="read_link">Mark All as Read</a>
                  </div>
                  
                  <div className="notification_scroll">
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              
              <div className="dropdown user_drop">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <figure className="m-0"><img src="images/user.jpg" alt="" /> <i><img src="images/user_ic.svg" alt="" /></i></figure>
                  <span>Jason Smith</span>
                </button>
                <div className="dropdown-menu">
                  <div className="user_info">
                    <figure><img src="images/user.jpg" alt="" /> <a href="#"><img src="images/editprof_ic.svg" alt="" /></a></figure>
                    <strong>Jason Smith</strong>
                    <span>jason@vspplatform.com</span>
                  </div>
                  <ul>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic1.svg" alt="" /></i> My Profile</a></li>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic2.svg" alt="" /></i> Settings</a></li>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic3.svg" alt="" /></i> Help & Support</a></li>
                  </ul>
                  <a href="index.html" className="logout_btn"><img src="images/logou_ic.svg" alt="" /> Log out</a>
                </div>
              </div>
            </div>
            <div className="middle">
              <select className="selectpicker">
                <option selected>Explore Courses</option>
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
              </select>
              <button className="search_button d-flex d-md-none" data-bs-target="#searchModal" data-bs-toggle="modal"><img src="images/search_ic.svg" alt="" /></button>
              <div className="input-group d-none d-md-flex">
                <span className="input-group-text"><img src="images/search_ic.svg" alt="" /></span>
                <input type="text" className="form-control" placeholder="What do you want to learn?" />
              </div>
            </div>
            
          </nav>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
