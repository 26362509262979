/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_COLOR = "CHANGE_COLOR"
export const CHANGE_MODE = "CHANGE_MODE"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"
export const SET_LOGIN_MODAL_STATE = "SET_LOGIN_MODAL_STATE"
export const SET_SIGNUP_MODAL_STATE = "SET_SIGNUP_MODAL_STATE"
export const GET_LOGIN_MODAL_STATE = "GET_LOGIN_MODAL_STATE"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"
