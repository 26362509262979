import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useLocation } from "react-router-dom";
import { calculateDiscount, formatOption, getCurrentUser, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality
import Intro from "./Intro";


const Header = (props) => {
//   document.title = "Home - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 
  const location = useLocation();
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)

  const [showAboutMeDropdown, setShowAboutMeDropdown] = useState(false);
  const [showMyTrainingDropdown, setShowMyTrainingDropdown] = useState(false);
  const [showMyCareerDropdown, setShowMyCareerDropdown] = useState(false);
  const [showMyJobsDropdown, setShowMyJobsDropdown] = useState(false);
  const [isNavbarShow, setIsNavbarShow] = useState(false);
  const [navBarClass, setNavBarClass] = useState('collapsed');



  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  const matchCurrentPath_ = (path) => {
    

    // Define the path or pattern you want to match
    const matchPattern = path; // Change this to your desired path or pattern

    // Check if the current URL matches the specified path or pattern
    const isMatch = location.pathname === matchPattern;
    console.log("M ",location.pathname,isMatch)
    return isMatch ? "active" : ""
  }

  const matchCurrentPath = (pathPart) => {
    const pathParts = location.pathname.split('/');
    
    const containsTraining = pathParts[2] === pathPart.replace("/","");
    return containsTraining ? "active" : ""
  }

  const matchHeaderPath = (pathPart) => {
    const pathParts = location.pathname.split('/');
    
    const containsTraining = pathParts[1] === pathPart.replace("/","");
    return containsTraining ? "active" : ""
  }

  const toggleNavBar = () => {
      setNavBarClass(!isNavbarShow ? '' : 'collapsed')
      setIsNavbarShow(!isNavbarShow)
  }

  return (
    <React.Fragment>
    
    { isLoading ? <Spinners  /> :
      <>
                    
                  {/* <!-- Inner Banner --> */}
                  <section className="inner_banner">
                    <div className="container">
                        <div className="inner">
                            <img className="bg" src="/images/inner_banner.jpg" alt="" />
                            <div className="cap_info">
                                <div className="detail">
                                    {/* <h1 style={{ lineHeight: 1.5 }}><small className="yellow-underline" style={{ width : 'fit-content' }}>Empowering your future</small><span className="yellow-underline">Elevate your skills and career</span></h1> */}
                                    <h1><small>Empowering your future</small>Elevate your skills and career</h1>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>



                <section className="about_sec pb-0">
                <div className="profile_sec about" style={{ paddingBottom : 0}}>
                        {/* <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-9">
                                    <div className="title">
                                        <span><img src="/images/rate_ic.svg" alt="" /></span>
                                        <h2 className="info">Welcome {getCurrentUser()?.first_name || "User"}</h2>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="label">Complete your Profile</div>
                                    <div className="progress_info">
                                        <div className="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: "90%"}}></div>
                                        </div>
                                        <div className="progress_time">
                                            <small>90%</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        <Intro />

                        <div className="container">
                            <nav className="navbar navbar-expand-lg" style={{ paddingTop : 55}}>
                                <div className="d-flex d-lg-none w-100 align-items-center justify-content-between">
                                    <span className="nav_txt">Navigation</span>
                                    <button className={`navbar-toggler ${navBarClass}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isNavbarShow} aria-label="Toggle navigation" onClick={(e) => {
                                        e.preventDefault()
                                        console.log("E ",isNavbarShow ? 'hide' : 'show')
                                        // setIsNavbarShow(isNavbarShow ? 'hide' : 'show')
                                        toggleNavBar()
                                        
                                    }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>			
                                    </button>
                                </div>

                                {/* <div className={ isNavbarShow ? `collapse navbar-collapse show` :  `collapse navbar-collapse`} id="navbarSupportedContent"> */}
                                <div className={ isNavbarShow ? `collapse navbar-collapse show` :  `collapse navbar-collapse`}>
                                    <ul className="nav nav-tabs">
                                        <li className={"nav-item "+matchHeaderPath("aboutme")}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                setShowAboutMeDropdown(!showAboutMeDropdown);
                                                setShowMyTrainingDropdown(false);
                                                setShowMyCareerDropdown(false);
                                                setShowMyJobsDropdown(false);
                                            }} 
                                            className={"nav-link custom-nav-link "+matchHeaderPath("aboutme")}>
                                                <i><img src="/images/about_tab_ic1.svg" alt="" /></i>
                                                About Me
                                            </a>
                                            <Dropdown show={showAboutMeDropdown} className="dropdown">
                                                <Dropdown.Toggle onClick={(e) => {
                                                e.preventDefault();
                                                setShowAboutMeDropdown(!showAboutMeDropdown);
                                                setShowMyTrainingDropdown(false);
                                                setShowMyCareerDropdown(false);
                                                setShowMyJobsDropdown(false);

                                            }} as="span" className="nav_link dropdown-toggle custom-dropdown-toggle" id="navbarDropdown"></Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul style={{ paddingLeft : 0 }}>
                                                    <li>
                                                        <Dropdown.Item href="/aboutme/my-profile" className={"dropdown-item "+matchCurrentPath("my-profile")}>
                                                        <i><img src="/images/profile_drop_ic1.svg" alt="" /></i> My Profile
                                                        </Dropdown.Item>
                                                    </li>
                                                    <li>
                                                        {/* <Link to="/my-panel" className=""> */}
                                                        <Dropdown.Item href="/aboutme/my-panel" className={"dropdown-item "+matchCurrentPath("my-panel")}>
                                                        <i><img src="/images/profile_drop_ic2.svg" alt="" /></i> My Panel
                                                        </Dropdown.Item>
                                                        {/* </Link> */}
                                                    </li>
                                                    {/* <li>
                                                        <Dropdown.Item href="/aboutme/my-skill" className={"dropdown-item "+matchCurrentPath("my-skill")}>
                                                        <i><img src="/images/profile_drop_ic3.svg" alt="" /></i> My Skills
                                                        </Dropdown.Item>
                                                    </li> */}
                                                    {/* <li>
                                                        <Dropdown.Item href="/aboutme/my-answer" className={"dropdown-item "+matchCurrentPath("my-answer")}>
                                                        <i><img src="/images/profile_drop_ic4.svg" alt="" /></i> My Answers
                                                        </Dropdown.Item>
                                                    </li> */}
                                                    <li>
                                                        <Dropdown.Item href="/aboutme/my-experience" className={"dropdown-item "+matchCurrentPath("my-experience")}>
                                                        <i><img src="/images/profile_drop_ic5.svg" alt="" /></i> My Experience
                                                        </Dropdown.Item>
                                                    </li>
                                                    <li>
                                                        <Dropdown.Item href="/aboutme/my-qualification" className={"dropdown-item "+matchCurrentPath("my-qualification")}>
                                                        <i><img src="/images/profile_drop_ic5.svg" alt="" /></i> My Qualifications
                                                        </Dropdown.Item>
                                                    </li>
                                                    {/* <li>
                                                        <Dropdown.Item href="/aboutme/jobs" className={"dropdown-item "+matchCurrentPath("jobs")}>
                                                        <i><img src="/images/profile_drop_ic6.svg" alt="" /></i> Search a Job
                                                        </Dropdown.Item>
                                                    </li> */}
                                                    <li>
                                                        <Dropdown.Item href="/aboutme/build-my-cv" className={"dropdown-item "+matchCurrentPath("build-my-cv")}>
                                                        <i><img src="/images/profile_drop_ic7.svg" alt="" /></i> Build your CV
                                                        </Dropdown.Item>
                                                    </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            
                                        </li>
                                        <li className={"nav-item "+matchHeaderPath("training")}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyTrainingDropdown(!showMyTrainingDropdown);
                                                setShowMyCareerDropdown(false);
                                                setShowAboutMeDropdown(false)
                                                setShowMyJobsDropdown(false)
                                            }} className={"nav-link custom-nav-link "+matchHeaderPath("training")}>
                                                <i><img src="/images/about_tab_ic2.svg" alt="" /></i>
                                                My Training
                                            </a>
                                            <Dropdown show={showMyTrainingDropdown}>
                                                <Dropdown.Toggle as="span" onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyTrainingDropdown(!showMyTrainingDropdown);
                                                setShowMyCareerDropdown(false);
                                                setShowAboutMeDropdown(false)
                                                setShowMyJobsDropdown(false)
                                            }} className="nav_link dropdown-toggle custom-dropdown-toggle" id="navbarDropdown"></Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul style={{ paddingLeft : 0 }}>
                                                    {/* <li>
                                                        <Dropdown.Item href="/training/my-training-programs" className={"dropdown-item "+matchCurrentPath("my-training-programs")+matchCurrentPath("my-training-detail")}>
                                                        <i><img src="/images/profile_drop_ic8.svg" alt="" /></i> My Training Programs
                                                        </Dropdown.Item>
                                                    </li> */}
                                                    <li>
                                                        <Dropdown.Item href="/training/my-training-path" className={"dropdown-item "+matchCurrentPath("my-training-path")}>
                                                        <i><img src="/images/profile_drop_ic9.svg" alt="" /></i> My Training Path
                                                        </Dropdown.Item>
                                                    </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                        </li>
                                        <li className={"nav-item "+matchHeaderPath("career")}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyCareerDropdown(!showMyCareerDropdown);
                                                setShowAboutMeDropdown(false);
                                                setShowMyTrainingDropdown(false)
                                                setShowMyJobsDropdown(false)
                                            }} className={"nav-link custom-nav-link "+matchHeaderPath("career")}>
                                                <i><img src="/images/about_tab_ic3.svg" alt="" /></i>
                                                My Career
                                            </a>
                                            <Dropdown show={showMyCareerDropdown}>
                                                <Dropdown.Toggle as="span" onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyCareerDropdown(!showMyCareerDropdown);
                                                setShowAboutMeDropdown(false);
                                                setShowMyTrainingDropdown(false)
                                                setShowMyJobsDropdown(false)
                                            }} className="nav_link dropdown-toggle custom-dropdown-toggle" id="navbarDropdown"></Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul style={{ paddingLeft : 0 }}>
                                                    {/* <li>
                                                        <Dropdown.Item href="/career/my-career" className={"dropdown-item "+matchCurrentPath("my-career")}>
                                                        <i><img src="/images/profile_drop_ic10.svg" alt="" /></i> My Career
                                                        </Dropdown.Item>
                                                    </li> */}
                                                    <li>
                                                        <Dropdown.Item href="/career/my-career-path" className={"dropdown-item "+matchCurrentPath("my-career-path")}>
                                                            <i><img src="/images/profile_drop_ic11.svg" alt="" /></i> My Career Path
                                                        </Dropdown.Item>
                                                    </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* <div className="dropdown">
                                                <a className="nav_link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><a className="dropdown-item" href="career.html"><i><img src="images/profile_drop_ic10.svg" alt="" /></i>My Career</a></li>
                                                    <li><a className="dropdown-item" href="career_path.html"><i><img src="images/profile_drop_ic11.svg" alt="" /></i>My Career Path</a></li>
                                                </ul>
                                            </div> */}
                                        </li>
                                        <li className={"nav-item "+matchHeaderPath("jobs")}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyJobsDropdown(!showMyJobsDropdown);
                                                setShowMyCareerDropdown(false);
                                                setShowAboutMeDropdown(false);
                                                setShowMyTrainingDropdown(false)
                                            }} className={"nav-link custom-nav-link "+matchHeaderPath("jobs")}>
                                                <i><img src="/images/about_tab_ic4.svg" alt="" /></i>
                                                My Jobs
                                            </a>
                                            <Dropdown show={showMyJobsDropdown}>
                                                <Dropdown.Toggle as="span" onClick={(e) => {
                                                e.preventDefault();
                                                setShowMyJobsDropdown(!showMyJobsDropdown);
                                                setShowMyCareerDropdown(false);
                                                setShowAboutMeDropdown(false);
                                                setShowMyTrainingDropdown(false)
                                            }} className="nav_link dropdown-toggle custom-dropdown-toggle" id="navbarDropdown"></Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul style={{ paddingLeft : 0 }}>
                                                    <li>
                                                        <Dropdown.Item href="/jobs/search" className={"dropdown-item "+matchCurrentPath("search")}>
                                                        <i><img src="/images/profile_drop_ic6.svg" alt="" /></i> Search a Job
                                                        </Dropdown.Item>
                                                    </li>
                                                    <li>
                                                        <Dropdown.Item href="/jobs/recommended-jobs" className={"dropdown-item "+matchCurrentPath("recommended-jobs")}>
                                                        <i><img src="/images/about_tab_ic4.svg" alt="" /></i> Recommended Jobs
                                                        </Dropdown.Item>
                                                    </li>
                                                    <li>
                                                        <Dropdown.Item href="/jobs/saved-jobs" className={"dropdown-item "+matchCurrentPath("saved-jobs")}>
                                                        <i><img src="/images/about_tab_ic4.svg" alt="" /></i> Saved Jobs
                                                        </Dropdown.Item>
                                                    </li>
                                                    
                                                    <li>
                                                        <Dropdown.Item href="/jobs/analytics" className={"dropdown-item "+matchCurrentPath("analytics")}>
                                                        <i><img src="/images/about_tab_ic4.svg" alt="" /></i> Jobs Analytics
                                                        </Dropdown.Item>
                                                    </li>
                                                    
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                          
                                        </li>
                                        {/* <li className={"nav-item "+matchHeaderPath("jobs")}>
                                            <Link to="/jobs/saved-jobs" className={"nav-link "+matchHeaderPath("jobs")}>
                                                <i><img src="/images/about_tab_ic4.svg" alt="" /></i>
                                                Saved Jobs
                                            </Link>
                                        </li> */}

                                        {/* <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i><img src="/images/about_tab_ic5.svg" alt="" /></i>
                                                Online Help
                                            </a>
                                            <Dropdown>
                                                <Dropdown.Toggle as="a" className="nav_link dropdown-toggle" id="navbarDropdown"></Dropdown.Toggle>
                                               
                                            </Dropdown>
                                        </li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>

                    </div>
                </section>
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Header);