import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality


const Footer = (props) => {
//   document.title = "Home - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)



  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

  const openURL = (url) => {
    window.open(url)
  }


  return (
    <React.Fragment>
        <>
        {/* <!-- Partner --> */}
        <section className="partner_sec partner_inner">
                    <div className="container">
                        <div className="partner_logo">
                            <img src="/images/partner_bg.jpg" className="bg" alt="" />
                            {/* <div className="owl-carousel PartnerSlider"> */}
                            <OwlCarousel className="owl-carousel PartnerSlider" items={4} loop dots={false} margin={10}>
                                <div className="footer-partner-icon" onClick={() => openURL("https://www.bournemouth.ac.uk/") } className="item">
                                    <figure><img src="/images/partner_logo_1.png" alt="" /></figure>
                                </div>
                                <div className="footer-partner-icon" onClick={() => openURL("https://www.skillsandlearningace.com/") } className="item">
                                    <figure><img src="/images/partner_logo_3.png" alt="" /></figure>
                                </div>
                                <div className="footer-partner-icon" onClick={() => openURL("https://aub.ac.uk/") } className="item">
                                    <figure><img src="/images/partner_logo_2.png" alt="" /></figure>
                                </div>
                                <div className="footer-partner-icon" onClick={() => openURL("https://www.thecollege.co.uk/") } className="item">
                                    <figure><img src="/images/partner_logo_4.png" alt="" /></figure>
                                </div>
                                {/* <div onClick={() => openURL("") } className="item">
                                    <figure><img src="/images/partner_logo_5.png" alt="" /></figure>
                                </div> */}
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
      </>
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Footer);