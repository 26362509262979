import React, { useEffect, useRef, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put, postFormData } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatDate, formatOption, getCurrentUser, handleImageError, loadImageURL, setCurrentUserProfilePercent, setCurrentUserProfilePercentWithResponse, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";
import moment from "moment";

const MyProfile = (props) => {
  document.title = "My Profile - VSP";

  const animatedComponents = makeAnimated();

  const COUNTRY_CODE_PREFIX = "(+44)-"
 
  const GENDERS =  [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" }
    ]

//   const INTERESTS =  [
//         { label: "Learn a new skill", value: "Learn a new skill" },
//         { label: "Search for job", value: "Search for job" },
//         { label: "Other", value: "other" }
//     ]
    
  const ETHNICITIES =  [
        { label: "Prefer not to say", value: "Prefer not to say" },
        { label: "American Indian or Alaska Native", value: "American Indian or Alaska Native" },
        { label: "Asian", value: "Asian" },
        { label: "Black or African American", value: "Black or African American" },
        { label: "Hispanic or Latino", value: "Hispanic or Latino" },
        { label: "Native Hawaiian or Other Pacific Islander", value: "Native Hawaiian or Other Pacific Islander" },
        { label: "White", value: "White" },
    ]

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [interests, setInterests] = useState([])
  const fileInputRef = useRef(null);

  const [formValues, setFormValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email : "",
    mobileNo : "",
    age : "",
    birthdate : new Date(),
    gender : { label: "Male", value: "male" },
    ethnicity : {},
    address : "",
    postal_code : "",
    description : "",
    interest : {},
    avatar: "",
    profile_picture: "",
    existingImage: "",
    existingImageBase64: null
  })

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please enter first name"),
      middle_name: Yup.string().optional(),
      last_name: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      mobileNo: Yup.string().required("Please enter mobile number"),
      birthdate: Yup.date().required("Please enter birthdate date"),
    //   age: Yup.number().required("Please enter age"),
      //   gender: Yup.object().required("Please select gender"),
      //   ethnicity: Yup.object().required("Please select ethnicity"),
      address: Yup.string().required("Please enter address"),
      postal_code: Yup.string().required("Please enter postal code"),
      description: Yup.string().required("Please enter about me"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here

      try {


            setLoading(true);
            
            // console.log("SUBMITTED")
            //   setTimeout(() => {
            //     setLoading(false)
            //     setEditMode(false)
            //   },3000)

            let newBody = Object.assign({}, values);

            newBody.interest_id = newBody.interest.value
            delete newBody.interest

            newBody.ethnicity = newBody.ethnicity.value

            newBody.mobile_no = newBody.mobileNo.split("-")[1]
            delete newBody.mobileNo

            newBody.aboutme = newBody.description
            delete newBody.description
            delete newBody.age

            newBody.gender = newBody.gender.value.toLowerCase()

            delete newBody.existingImageBase64
            delete newBody.existingImage

            // let fullName = newBody.first_name.split(" ")
            // newBody.first_name = fullName[0]
            // newBody.first_name = fullName[]
                
            

            const formData = new FormData();

            const localDatetime = new Date(values.birthdate);
            const birthdate = new Date(localDatetime.getTime() - localDatetime.getTimezoneOffset() * 60000).toISOString();

            // Add the birthdate as an ISO string to FormData
            formData.append('birthdate', birthdate);

            // Iterate over the key-value pairs in the JSON object
            for (const key in newBody) {
                if (key !== 'birthdate') {
                    if (Object.hasOwnProperty.call(newBody, key)) {
                        const value = newBody[key];
                        // Append each key-value pair to the FormData
                        formData.append(key, value);
                    }
                }
            }

            for (var key of formData.entries()) {
                console.log(key[0] + ', ' , key[1])
            }

            // console.log("BODY ",newBody)
            
            // setLoading(false)
            // return

            postFormData(url.UPDATE_USER_INFO, formData, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
                let message = response?.response || "";
                let responseData = response?.data || [];

                setCurrentUserProfilePercentWithResponse(response);

                // console.log("RES UPDATE PROFILE ",response)
                if(!response.status) {

                    window.alert("Success!\n"+response.error[0].message)
                    setLoading(false)
                    // setEditMode(false)
                    return
                }

                let existingUserObj = getCurrentUser();

                const updatedObject = { ...existingUserObj, ...responseData };

                localStorage.setItem("authUser", JSON.stringify(updatedObject));
                
                // console.log("E ",updatedObject,response)
                
                window.alert("Success!\n"+message)
                // window.history.go(-1);
                setLoading(false)
                setEditMode(false)
                // window.location.reload();

                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Reset file input value to clear it
                }

            }).catch((e) => {
                // console.log("RES UPDATE PROFILE ERR ",e)
                window.alert("Sorry!\n"+e)
                setLoading(false)
            }).finally(() => {
            });   

      } catch(e) {
        setLoading(false)
      }
      
      
     
    },
  });

  

 


  useEffect(() => {
    async function fetchData() {

        get(url.GET_USER_INFO, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
            
            
            let responseData = response.data
            let interests = response.interests

            
            setCurrentUserProfilePercentWithResponse(response);

            let interestsArr = [];


            interests.map((interest,i) => {

                interestsArr.push({ label : interest.name, value : interest.id })
            })

            setInterests(interestsArr);

            // console.log("RESPONSE ",response)


            let user = getCurrentUser();
            // console.log("USER ",user)

            let userObj = {
                first_name: user.first_name,
                middle_name: user.middle_name,
                last_name: user.last_name,
                email : user.email,
                mobileNo : `(+44)-${user?.mobile_no || ""}`,
                // age : calculateAge(user?.birthdate || new Date()).toFixed(),
                birthdate : user?.birthdate || new Date(),
                gender : { label: user?.gender?.toUpperCase(), value: user.gender },
                ethnicity : { label: user?.ethnicity || "Prefer not to say", value: user?.ethnicity || "Prefer not to say" },
                address : user?.address || "-",
                postal_code : user?.postal_code || "",
                description : user?.about || "-",
                interest : { label: user?.interest?.name || "Learn a new skill", value: user?.interest?.id || "Learn a new skill" },
                existingImage : user?.profile_picture,
            }
            console.log("USR ",userObj)
            setFormValues(userObj)

            setLoading(false)

        }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)
        }).finally(() => {
        });   
        
        // setLoading(false)
      
    
    }
    
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  const handleGenderSelect = (selectedOptions) => {
    validation.setFieldValue('gender', selectedOptions);
  };

  const handleEthnicitySelect = (selectedOptions) => {
    validation.setFieldValue('ethnicity', selectedOptions);
  };
  const handleInterestSelect = (selectedOptions) => {
    validation.setFieldValue('interest', selectedOptions);
  };

  const handleImageChange = event => {
    //   console.log("E",event.target.files.length == 0,event.target.files)
    let file = event.target.files[0]
    validation.setFieldValue('profile_picture',file);

    if(event.target.files.length != 0) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const fileURI = e.target.result;
            // console.log("File URI:", fileURI);
            validation.setFieldValue('existingImageBase64', fileURI);
            // Now you can use the fileURI as needed
        };

        reader.readAsDataURL(file);
    }

  };

  const handleChangeMobileNo = (e, setFieldValue) => {
    let inputValue = e.target.value.trim();
    const prefix = COUNTRY_CODE_PREFIX;

    const regex = /^[0-9+()-]*$/;

    if (!regex.test(inputValue)) {
      // If input contains characters other than numbers and "+", remove them
      inputValue = inputValue.replace(/[^0-9+()-]/g, '');
    }


    if(inputValue.length >= 6 && inputValue.length <= 16) {
        if (inputValue === COUNTRY_CODE_PREFIX) {
            validation.setFieldValue('mobileNo', COUNTRY_CODE_PREFIX);
            // console.log("L 1",inputValue.length,inputValue)
        } else if (!inputValue.startsWith(prefix)) {
          validation.setFieldValue('mobileNo', prefix + inputValue);
        //   console.log("L 2",inputValue.length,inputValue)
        } else {
            validation.setFieldValue('mobileNo', inputValue);
            // console.log("LL ",inputValue.length,inputValue)
        }
    }
 
  };

  const calculateAge = (date) => {
    const birthdate = moment(date); // Parse the given date
    const currentDate = moment(); // Get the current date

    // Calculate the difference in years
    const age = currentDate.diff(birthdate, 'years');

    // console.log("AGE ",age,date)
    return age;
  };

  return (
    <React.Fragment>
    { 
    isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  /></div></section></> :
      <>
                    
                
                <Header />


            <section className="about_sec">
                <Form className="needs-validation"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}> 
                    <div className="container">
                      
                        
                        {/* <!-- About --> */}
                        <div className="about_main">
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                <li><a href="#" title="About Me">About Me</a></li>
                                <li>My Profile</li>
                            </ul>
                            <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                <div className="col-md-auto col-sm-12">
                                    <h3>My Profile</h3>
                                </div>
                                <div className="col-md-auto col-sm-12 text-end">
                                    {/* <a href="about_me_edit.html" className="btns gray"><img src="images/edit_ic.svg" alt="" />Edit Profile</a> */}
                                    { isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners  /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                    {/* <Button className="btns green" color="primary" type="submit">
                                        Save
                                    </Button> */}
					                { !isEditMode && <button type="button" onClick={() => setEditMode(true) } className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Profile</button>}
                                </div>
                            </div>
                            <div className="about_block">

                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">First Name</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="first_name"
                                                    placeholder="Enter first name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.first_name || ''}
                                                    invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                                                />
                                                {validation.touched.first_name && validation.errors.first_name ? (
                                                    <div className="invalid-feedback">{validation.errors.first_name}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.first_name || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Middle Name</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="middle_name"
                                                    placeholder="Enter middle name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.middle_name || ''}
                                                    invalid={validation.touched.middle_name && validation.errors.middle_name ? true : false}
                                                />
                                                {validation.touched.middle_name && validation.errors.middle_name ? (
                                                    <div className="invalid-feedback">{validation.errors.middle_name}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.middle_name || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Last Name</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="last_name"
                                                    placeholder="Enter last name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.last_name || ''}
                                                    invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                                                />
                                                {validation.touched.last_name && validation.errors.last_name ? (
                                                    <div className="invalid-feedback">{validation.errors.last_name}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.last_name || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-sm-6">
                                        <figure className="edit_user">
                                        {/* <input type="file" name="image"
                                            ref={fileInputRef}
                                            onChange={(event) => {
                                                validation.setFieldValue('image', event.target.files[0]);
                                            }}
                                            className="form-control form-control-file" data-buttonname="btn-secondary" 
                                            /> */}
                                            <input
                                                ref={fileInputRef}
                                                id="image"
                                                name="image"
                                                type="file"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }} // Hide the input element
                                                />
                                                {/* {console.log("V ",loadImageURL(validation.values.existingImage))} */}
                                            <img 
                                                src={validation.values.existingImageBase64 != null ? validation.values.existingImageBase64 : loadImageURL(validation.values.existingImage)}
                                                alt="Avatar" 
                                                className="rounded border-rounded"                   
                                                onError={handleImageError}  
                                            />
                                            
                                           { isEditMode && <button type="button" onClick={() => document.getElementById('image').click()} class="edit"><img src="/images/edit_ic.svg" alt="" /></button>}
                                        </figure>
                                    </div>

                                </div>

                                <div className="row">
                                    {/* <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Full Name</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="first_name"
                                                    placeholder="Enter first name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.first_name || ''}
                                                    invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                                                />
                                                {validation.touched.first_name && validation.errors.first_name ? (
                                                    <div className="invalid-feedback">{validation.errors.first_name}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.first_name || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div> */}

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">

                                            <Label htmlFor="validationCustom01">Mobile</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                <Input
                                                    name="mobileNo"
                                                    placeholder="Enter mobile number"
                                                    type="text"
                                                    // onChange={validation.handleChange}
                                                    onChange={validation.handleChange && handleChangeMobileNo}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.mobileNo || ''}
                                                    invalid={validation.touched.mobileNo && validation.errors.mobileNo ? true : false}
                                                />
                                                {validation.touched.mobileNo && validation.errors.mobileNo ? (
                                                    <div className="invalid-feedback">{validation.errors.mobileNo}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.mobileNo || ''}</h5>
                                            }
                                            
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-5 col-md-3 col-sm-6">
                                        <div className="icon_cap">
                                            <i><img src="images/rate_ic_small.svg" alt="" /></i>
                                            <div className="title">Stellar</div>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Email</label>
                                            <h5>jasonsmith@gmail.com</h5> */}


                                            <Label>Email</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="email"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ''}
                                                    invalid={validation.touched.email && validation.errors.email ? true : false}
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <div className="invalid-feedback">{validation.errors.email}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.email || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className="row">
                                    <div className="col-md-2 col-sm-4">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Age</label>
                                            <h5>25 yrs.</h5> */}
                                            <Label>Birth Date</Label>
                                            {/* {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="age"
                                                    placeholder="Enter age"
                                                    type="number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.age || ''}
                                                    invalid={validation.touched.age && validation.errors.age ? true : false}
                                                />
                                                {validation.touched.age && validation.errors.age ? (
                                                    <div className="invalid-feedback">{validation.errors.age}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.age}</h5>
                                            } */}

                                            {                                   
                                                isEditMode ? 
                                                <>
                                                    
                                                    <Flatpickr
                                                        name="birthdate"
                                                        className={`form-control d-block ${validation.touched.birthdate && validation.errors.birthdate ? 'is-invalid' : ''}`}
                                                        placeholder="DD/MM/YYYY"
                                                        value={validation.values.birthdate}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d/m/Y",
                                                            dateFormat: "Y-m-d"
                                                        }}
                                                        onChange={(selectedDates) => {
                                                            validation.setFieldValue('birthdate', selectedDates[0]);
                                                        }}
                                                    />
                                                    {validation.touched.birthdate && validation.errors.birthdate ? (
                                                        <div className="text-danger">{validation.errors.birthdate}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>{formatDate(validation.values?.birthdate || new Date()) || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-4">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Sex</label>
                                            <h5>Female </h5> */}

                                            <Label>Gender</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Select
                                                        value={validation.values.gender}
                                                        isMulti={false}
                                                        onChange={handleGenderSelect}
                                                        options={GENDERS}
                                                        id="gender"
                                                        classNamePrefix="selectpicker"
                                                        isValid={!validation.errors.gender && validation.touched.gender}
                                                    />
                                                    {validation.touched.gender && validation.errors.gender ? (
                                                        <div className="invalid-feedback">{validation.errors.gender}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.gender.label || ''}</h5>
                                            }
                                            
                                            {/* {
                                                isEditMode ? 
                                                <>
                                                    <select class="selectpicker genderpicker">
                                                        <option selected>Female</option>
                                                        <option>Male</option>
                                                        <option>More</option>
                                                    </select>
                                                    {validation.touched.gender && validation.errors.gender ? (
                                                        <div className="invalid-feedback">{validation.errors.gender}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>Male</h5>
                                            } */}
                                            

                                        

                                            
                                           
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3 col-sm-4">
                                        <div className="input_group">
                                            
                                            <Label>Ethnicity</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Select
                                                        value={validation.values.ethnicity}
                                                        isMulti={false}
                                                        onChange={handleEthnicitySelect}
                                                        options={ETHNICITIES}
                                                        id="ethnicity"
                                                        classNamePrefix="selectpicker"
                                                        isValid={!validation.errors.ethnicity && validation.touched.ethnicity}
                                                    />
                                                    {validation.touched.ethnicity && validation.errors.ethnicity ? (
                                                        <div className="invalid-feedback">{validation.errors.ethnicity}</div>
                                                    ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.ethnicity.label || ''}</h5>
                                            }
                                        </div>
                                    </div> */}
                                    
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-9 col-sm-12">
                                        <div className="input_group">
                                            {/* <label htmlFor="text" className="form-label">Address</label>
                                            <h5>BH2, Bournemouth, England</h5> */}
                                            <Label>Address</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="address"
                                                    placeholder="Enter address"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.address || ''}
                                                    invalid={validation.touched.address && validation.errors.address ? true : false}
                                                />
                                                {validation.touched.address && validation.errors.address ? (
                                                    <div className="invalid-feedback">{validation.errors.address}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.address || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="input_group">
                                            <Label>Postal Code</Label>
                                            {
                                                isEditMode ? 
                                                <>
                                                    <Input
                                                    name="postal_code"
                                                    placeholder="Enter postal code"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.postal_code || ''}
                                                    invalid={validation.touched.postal_code && validation.errors.postal_code ? true : false}
                                                />
                                                {validation.touched.postal_code && validation.errors.postal_code ? (
                                                    <div className="invalid-feedback">{validation.errors.postal_code}</div>
                                                ) : null}
                                                </>
                                                :
                                                <h5>{validation.values.postal_code || ''}</h5>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="input_group">
                                    {/* <label htmlFor="text" className="form-label">Description</label>
                                    <h5>Lorem ipsum dolor sit amet, ligula eget dolor. cnsectetuer adipiscing elit. Donec quam felis, ultricies nec, pellentesque eu, pretium quis sem.</h5> */}

                                    <Label>About Me</Label>
                                    {
                                        isEditMode ? 
                                        <>
                                            <Input
                                                name="description"
                                                placeholder="Enter description"
                                                type="textarea"
                                                style={{ minHeight : 100 }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ''}
                                                invalid={validation.touched.description && validation.errors.description ? true : false}
                                            />
                                        {validation.touched.description && validation.errors.description ? (
                                            <div className="invalid-feedback">{validation.errors.description}</div>
                                        ) : null}
                                        </>
                                        :
                                        <h5>{validation.values.description || ''}</h5>
                                    }
                                </div>
                                <div className="col-md-2 col-sm-12">
                                    <div className="input_group">
                                    {/* <div className="selected_label">I am interested in</div> */}
                                        <Label>I am interested in</Label>
                                        {
                                            isEditMode ?
                                                <>
                                                    <Select
                                                        value={validation.values.interest}
                                                        isMulti={false}
                                                        onChange={handleInterestSelect}
                                                        options={interests}
                                                        id="interest"
                                                        classNamePrefix="selectpicker"
                                                        isValid={!validation.errors.interest && validation.touched.interest}
                                                    />
                                                {validation.touched.interest && validation.errors.interest ? (
                                                    <div className="invalid-feedback">{validation.errors.interest}</div>
                                                ) : null}
                                        </>
                                        :
                                        <h5>{validation.values.interest.label || ''}</h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Form>
            </section>

            
                <Footer />
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyProfile);