import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Spinners from "components/Common/Spinner"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCurrentUser, handleImageError, setCurrentUserProfilePercent, setCurrentUserProfilePercentWithResponse } from "helpers/functions";
import { API_BASE_URL, GET_RECOMMENDATIONS } from "helpers/url_helper";
import Header from "./Header";
import { post } from "helpers/api_helper";

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Intro from "./Intro";



const Dashboard = (props) => {
    document.title = "Dashboard - Virtual Skills Platform";

    // const options = {
    //     items: 4,
    //     margin: 28,
    //     dots: false,
    //     freeDrag: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 2
    //         },
    //         1000: {
    //             items: 4
    //         }
    //     }
    // };
    const options = {
        items: 4,             // Number of items to display at once
        margin: 28,           // Margin between items
        dots: false,          // Disable dots navigation
        loop: true,           // Enable continuous loop mode
        autoplay: true,       // Enable autoplay
        autoplayTimeout: 3000,// Set autoplay interval (in ms)
        autoplayHoverPause: true, // Pause on hover
        smartSpeed: 600,      // Smooth transition speed (in ms)
        responsive: {         // Responsive breakpoints
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    };

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    // const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const location = useLocation()

    const [skillsBasedCourses, setSkillsBasedCourses] = useState([])
    const [desiredRoleBasedCourses, setDesiredRoleBasedCourses] = useState([])
    const [openedCoursesBased, setOpenedCoursesBased] = useState([])
    const [currentDesiredRoleCourse, setCurrentDesiredRoleCourse] = useState(null)
    const [openedCourseDetail, setOpenedCourseDetail] = useState(null)


    useEffect(() => {

        // console.log("QC ",searchParams.get("q"))
        // Check if the 'editMode' parameter is present
        // if (searchParams) {

            // let searchQ = searchParams.get("q")
            // let searchC = searchParams.get("c")

            // setSearchQuery(searchParams.get("q"))
            // setSearchCategory(searchParams.get("c"))
            setLoading(true)
            

            async function getRecomendations() {

                let userInfo = getCurrentUser();

                let body = {}
                if(userInfo != null) {
                    // console.log("USER INFO ",userInfo)
                    body.user_id = userInfo.user_id
                } else {
                    // console.log("USER INFO BLANK" )
                }

                // get(GET_SEARCH_RESULT+`?c=${searchC}&q=${searchQ}`, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {
                post(GET_RECOMMENDATIONS, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

                  console.log("FETCHED ", response)

                  setCurrentUserProfilePercentWithResponse(response);

                  let responseData = response?.data || [];

                    setSkillsBasedCourses(responseData?.skillsBasedCourses?.courses || [])
                    setDesiredRoleBasedCourses(responseData?.desiredJobRolesBasedCourses?.courses || [])
                    setCurrentDesiredRoleCourse(responseData?.basedOnOpenedCourses?.openedCourse || null)
                    setOpenedCoursesBased(responseData?.basedOnOpenedCourses?.courses || [])
                    setOpenedCourseDetail(responseData?.basedOnOpenedCourses?.openedCourse || null)

                //   setSearchResults(responseData)

                  
                  setLoading(false)

                }).catch((e) => {
                  window.alert("Sorry!\n" + JSON.stringify(e))
                  setLoading(false)
                }).finally(() => {
                  setLoading(false)
                });

            }
            getRecomendations();


            // setLoading(false)
            // setTimeout(() => {
            // },10000)
        // } else {
        //   setLoading(false)
        //     // setTimeout(() => {
        //     //     setLoading(false)
        //     // },10000)
        // }
    }, []);


    const renderCourseThumb = (course,index) => {
        return(
            <div className="" style={{ marginLeft : 0, marginRight : 0, width : '90%' }}>
                <div className="category_box" style={{ borderStyle : 'solid', borderWidth : 1, borderColor: "#ccc", }}>
                    <div className="inn">
                        {/* <img style={{ marginBottom : 20 }} src="../images/course_img_2.jpg" alt="" /> */}
                        <img style={{ marginBottom : 20 }} onError={handleImageError} src={API_BASE_URL+course?.institute_detail?.image || "#"} alt="" />
                        
                        <h3 className="h3-custom clamp-text-2">{course?.title}</h3>
                        <span>{course?.institute_detail?.name}</span>
                        <p style={{ margin : 0 }}>{course?.category_detail?.name}</p>
                    </div>
                </div>
        </div>)
    }

    return (
        <React.Fragment>
            {/* loading ? <><section className="about_sec"><div className="container text-center mt-40"><Spinners  color="dark" /></div></section></> : */}
            {
                <>

                <section className="profile_sec" style={{ paddingTop : 40, paddingBottom : 10}}>
                    <Intro />
                    <div className="container">
                        <div className="profile_menu">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <Link to={"/aboutme/my-profile"} className="profile_box">
                                        <div className="inn">
                                            <figure><img src="/images/profile_img1.svg" alt="" /></figure>
                                            <h4>About Me</h4>
                                            <p className="mb-0">View or update your personal information</p>
                                            {/* <span>Lorem ipsum</span> */}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <Link to={"/aboutme/my-panel"} className="profile_box">
                                        <div className="inn">
                                            <figure><img src="/images/profile_img4.svg" alt="" /></figure>
                                            <h4>My Panel</h4>
                                            <p className="mb-0">Personalize and update your career path goals</p>
                                            {/* <span>Lorem ipsum</span> */}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <Link to={"/training/my-training-path"} className="profile_box">
                                        <div className="inn">
                                            <figure><img src="/images/profile_img2.svg" alt="" /></figure>
                                            <h4>My Training Path</h4>
                                            <p className="mb-0">Achieve your goals with targeted training</p>
                                            {/* <span>Lorem ipsum</span> */}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <Link to={"/career/my-career-path"} className="profile_box">
                                        <div className="inn">
                                            <figure><img src="/images/profile_img3.svg" alt="" /></figure>
                                            <h4>My Career Path</h4>
                                            <p className="mb-0">Pathway to your ultimate career objective</p>
                                            {/* <span>Lorem ipsum</span> */}
                                        </div>
                                    </Link>
                                </div>
                                
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-6">
                                    <div className="profile_block">
                                        <div className="profile_item">
                                            <div className="icon"><img src="/images/profile_ic1.svg" alt="" /></div>
                                            <div className="detail">
                                                <h5>My Training Path</h5>
                                                <p className="">Lorem ipsum dolor sit amet adipiscing quam.</p>
                                            </div>
                                        </div>
                                        <a href="#" className="more_link"><i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="profile_block green_item">
                                        <div className="profile_item">
                                            <div className="icon"><img src="/images/profile_ic2.svg" alt="" /></div>
                                            <div className="detail">
                                                <h5>My Career Path</h5>
                                                <p className="">Lorem ipsum dolor sit amet adipiscing quam.</p>
                                            </div>
                                        </div>
                                        <a href="#" className="more_link"><i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>


                           {/* <!-- Recommended --> */}
            { loading ? <><div className="container text-center mb-50 mt-00"><Spinners  color="dark" /></div></> : 
            
            <section className="category_sec" id="category_section" style={{backgroundColor:"white", paddingTop : 0}}>
                <div className="container">
                    {/* <h2 className="global_title text-center">Explore Inspiring Online Courses</h2> */}
                    { ((openedCoursesBased.length > 0 && openedCourseDetail) || desiredRoleBasedCourses.length > 0 || skillsBasedCourses.length > 0) && <h2 className="global_title " style={{ marginBottom : 50,}}>What to learn next?</h2>}


                    { openedCoursesBased.length > 0 && openedCourseDetail &&
                        <>
                            <h4 className="custom-carousel-title">Because you view <a target="_blank" href={openedCourseDetail?.course_link || "#"} className="">"{openedCourseDetail?.title || ""}"</a></h4>
                            <div className="row">
                            {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                            <Carousel responsive={responsive}>
                            { openedCoursesBased.map(renderCourseThumb)}
                            </Carousel>
                            </div>
                        </>
                    }

                    <br />

                    { desiredRoleBasedCourses.length > 0 &&
                        <>
                            <h4 className="custom-carousel-title">Recommended for you based on your desired role</h4>
                            <div className="row">
                            {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                            <Carousel responsive={responsive}>
                            { desiredRoleBasedCourses.map(renderCourseThumb)}
                            </Carousel>
                            </div>
                        </>
                    }

                    <br />

                    { skillsBasedCourses.length > 0 &&
                        <>
                            <h4 className="custom-carousel-title">Recommended for you based on your skills</h4>
                            <div className="row">
                            {/* <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4}  dots={false} margin={28}> */}
                            <Carousel responsive={responsive}>
                            { skillsBasedCourses.map(renderCourseThumb)}
                            </Carousel>
                            </div>
                        </>
                    }

                    { !((openedCoursesBased.length > 0 && openedCourseDetail) || desiredRoleBasedCourses.length > 0 || skillsBasedCourses.length > 0) && <button onClick={() => navigate('/aboutme/my-profile')} className="btns blue build-profile-btn">Complete your profile first to see our recommendation for you <img src="/images/right_arrow_ic.png" style={{ height : 30 }} alt="" /></button>}

                    {/* { skillsBasedCourses.length > 0 &&
                        <>
                            <h4 className="mb-30">Recommended for you based on your skills</h4>
                            <div className="row">
                            <OwlCarousel options={options} className="owl-carousel EverydayCarousel" items={4} dots={false} margin={28}>
                            { skillsBasedCourses.map(renderCourseThumb)}
                            </OwlCarousel>
                            </div>
                        </>
                    } */}
                      
                    
                </div>
            </section>}

        
                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);








  