import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from "pages/AboutMe/Footer";


const options = {
    items: 12, // Set the number of items to display at a time
    loop: true,
    margin: 28,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };

const AboutUs = (props) => {
  document.title = "About Us - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)



  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  return (
    <React.Fragment>
    
    { isLoading ? <Spinners  /> :
      <>
                 
            {/* <!-- Banner --> */}
            <div class="about_banner">
                <div class="container">
                    <h1>Empower your future - elevate your skills​</h1>
                    <div class="info">Are you working in the digital sector or planning a career in digital? Upskill and give your career a boost with our local training partners in Bournemouth and Poole.​</div>
                    <div class="detail">
                        <img src="images/about_banner_updated.png" alt="" />
                        <div class="cap_info">
                            <h2>The local platform for digital skills powered by Better Boscombe Towns Fund programme​</h2>
                            {/* <p>Lorem ipsum dolor sit amet imperdiet etiamcommodo ligula.</p> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Client --> */}
            <section class="client_sec">
                <div class="container">
                    <div class="owl-carousel ClientSlider">
                        <div class="item">
                            <figure><img src="images/about_logo_1.png" alt="" /></figure>
                        </div>

                        <div class="item">
                            <figure><img src="images/about_logo_2.png" alt="" /></figure>
                        </div>

                        <div class="item">
                            <figure><img src="images/about_logo_3.png" alt="" /></figure>
                        </div>

                        <div class="item">
                            <figure><img src="images/about_logo_4.png" alt="" /></figure>
                        </div>

                        <div class="item">
                            <figure><img src="images/about_logo_5.png" alt="" /></figure>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- About --> */}
            <section class="about_info" style={{ paddingTop: 0 }}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <h2 class="global_title" style={{ marginTop : 50, marginBottom : 0 }}><span>It’s all</span> About Us</h2>
                            {/* <div class="text-left">
                                <a href="#" class="watch_link"><i class="fa fa-play"></i>Watch Video</a>
                            </div> */}
                        </div>
                
                        <div class="col-md-9">
                            <p>Towns Fund is a scheme launched by the Government for towns to improve their economy. Bournemouth Christchurch and Poole Council <span className="underline-highlight">secured almost £22million as part of the ‘Town Deal’</span> to turbo charge ambitious regeneration plans for Boscombe, creating opportunities for our residents and businesses alike. Part of the grant is dedicated to helping local residents build their digital skills and enhance career opportunities in the digital sector.</p>
                            <p>For those of you already on your digital journey the Virtual Skills Platform is ideal to plan digital training with local providers in the BCP area, launching your career or stepping up to reach your goals more quickly. ​</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Up Skill --> */}
            <section class="upskill_sec">
                <div class="container">
                    <h2 class="global_title">What does the Virtual Skills Platform offer you?​</h2>
                    <div class="info"></div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="upskill_block">
                                <div class="inn">
                                    <figure><img src="images/upskill_ic_1.svg" alt="" /></figure>
                                    <h3>Upskill</h3>
                                    <p>See specialist training in the digital sector to enhance your skillset and your CV.​</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="upskill_block blue">
                                <div class="inn">
                                    <figure><img src="images/upskill_ic_2.svg" alt="" /></figure>
                                    <h3>Pathway</h3>
                                    <p>Create a training pathway aligned with your background and aspirations.​</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="upskill_block green">
                                <div class="inn">
                                    <figure><img src="images/upskill_ic_3.svg" alt="" /></figure>
                                    <h3>Goals</h3>
                                    <p>Set goals and see the potential for your personal career path.​</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Virtual --> */}
            <section class="virtual_sec">
                <div class="container">
                    <div class="inner">
                        <div class="row gx-5">
                            <div class="col-md-6">
                                <figure><a href="#"><img src="images/virtual_img.jpg" alt="" /> <img src="images/play_ic.svg" class="play" alt="" /></a></figure>
                            </div>

                            <div class="col-md-6">
                                <div class="detail">
                                    <h2><span>Virtual</span> Skill Platform</h2>
                                    <ul class="job_list">
                                        <li>Specialised Training to upskill themselves and become better qualified for job roles. </li>
                                        <li>A training pathway based on their appetite and their background, which improves qualifications.</li>
                                        <li>A potential career pathway based on their future goals, current background and probable future training and qualifications..</li>
                                    </ul>
                                    <a href="#" class="btns bdr">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Team --> */}
            {/* <section class="team_sec">
                <div class="container">
                    <h2 class="global_title text-white">Meet Our Team</h2>
                    <div class="info text-white">Lorem ipsum dolor sit amet, consectetuer elitaenean Quisque rutrum aenean imperdiet Etiamcommodo ligula eget dolor massa.</div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="team_block">
                                <figure><img src="images/team_img_1.jpg" alt="" /></figure>
                                <div class="detail">
                                    <h3>John Carter <small>CEO & Co Founder</small></h3>
                                    <ul class="team_social">
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="team_block">
                                <figure><img src="images/team_img_2.jpg" alt="" /></figure>
                                <div class="detail">
                                    <h3>Nick Smith <small>Chief Technical Officer</small></h3>
                                    <ul class="team_social">
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="team_block">
                                <figure><img src="images/team_img_3.jpg" alt="" /></figure>
                                <div class="detail">
                                    <h3>Daniel Oneil <small>Business Development</small></h3>
                                    <ul class="team_social">
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="team_block">
                                <figure><img src="images/team_img_4.jpg" alt="" /></figure>
                                <div class="detail">
                                    <h3>Ruby Villar <small>Sales & Support</small></h3>
                                    <ul class="team_social">
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <!-- VSP --> */}
            <section class="vsp_sec" style={{ paddingTop: 0 }}>
                <div class="container">
                    <h2 class="global_title">Start Learning with Virtual Skills Platform​</h2>
                    <div class="info">Explore the courses available below or create an account for a personal skills pathway.​</div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6">
                            <div class="vsp_block">
                                <strong><span class="counter">16</span>k+</strong>
                                {/* <p>Lorem ipsum dolor </p> */}
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6">
                            <div class="vsp_block">
                                <strong><span class="counter">12</span>k+</strong>
                                {/* <p>Lorem ipsum dolor </p> */}
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6">
                            <div class="vsp_block">
                                <strong><span class="counter">99</span>%</strong>
                                {/* <p>Lorem ipsum dolor </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AboutUs);