
import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { calculateDiscount, formatOption, formatDate, getCurrentUser } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "../AboutMe/Header";
import Footer from "../AboutMe//Footer";
import MyQualificationForm from "../Partials/MyQualificationForm";

import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';


const MyTrainingPath = (props) => {
  document.title = "My Training Path - Virtual Skills Platform";

  const animatedComponents = makeAnimated();


  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)


  const { id } = useParams(); 

  const [searchParams, setSearchParams] = useSearchParams();

  // Get a specific query parameter
  const height = searchParams.get('h');

  const [coursesList, setCoursesList] = useState([])
  const [institutesList, setInstitutesList] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [isDisableAddBtn, setIsDisableAddBtn] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const navigate = useNavigate();

  const initialNodes = [];


  const initialEdges = [];

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  
  useEffect(() => {

    // Check if the 'editMode' parameter is present
    if (id) {

      async function fetchData() {

        post(url.GENERATE_USER_TRAINING_PATH_APP+id, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {
          let responseData = response.data


          if(responseData == 'NO_DESIRED_ROLE') {
            window.alert("Can not generate path!\nBecause you need to define desired role first.")
            // navigate(`/aboutme/my-panel`);
            setLoading(false)
            return
          } else if(responseData == 'NOT_VALID_USER_ID') {
            window.alert("Something went wrong, Please contact you administrator.")
            // navigate(`/aboutme/my-panel`);
            setLoading(false)
            return
          } else if(responseData == 'NO_USER_ID') {
            window.alert("Something went wrong, Please contact you administrator.")
            // navigate(`/aboutme/my-panel`);
            setLoading(false)
            return
          }
          
          let user = response.user
          let nodes = responseData.nodes
          let edges = responseData.edges
          
          setNodes(nodes);
          setEdges(edges);
          setUserInfo(user)

          setLoading(false)

      }).catch((e) => {
          // window.alert("Sorry!\n"+e)
          setLoading(false)
      }).finally(() => {
      });   
      
    
    }
    fetchData();


    }
  }, [id]);



//   const handlejobroleSelect = (selectedOptions) => {
//     validation.setFieldValue('job_role_id', selectedOptions);
//   };


const redirectToCourse = (e,node) => {
  // console.log("N",node)
  if(node.data.link == "") {
    window.alert("Sorry!\nCan not open course becuase coudn't find course URL.")
  } else {
    window.open(node.data.link, '_blank');
  }
}


  return (
    <React.Fragment>
    { 
      isLoading ? <><section className="about_sec"><div className="container text-center"><Spinners  color="dark" /></div></section></> :
      <>
                    
               
            <section className="about_sec">
                <div className="container">
                      
                        
                        {/* <!-- About --> */}
                        <div className="about_main">
                            
                      
                            

                                { nodes.length > 0 ?
                                  <div className="about_block" style={{ marginTop : 10, height : parseInt(height) || 500, width : '100%' }}> 
                                  <ReactFlow
                                      nodes={nodes}
                                      onNodeClick={(e,node) => redirectToCourse(e,node)}
                                      // onNodesChange={onNodesChange}
                                      edges={edges}
                                      // onEdgesChange={onEdgesChange}
                                      fitView
                                    >
                                    {/* <Background /> */}
                                    {/* <MiniMap/> */}
                                    <Controls />
                                  </ReactFlow>
                                </div>
                                :
                                <h3 className="text-center">There is no path details for job role: <strong>{userInfo?.desiredJobRole?.name || "-"}</strong> </h3>
                                }

                                {/* <a href="javascript:void(0)" className="add_desire mb-3" data-bs-toggle="modal" data-bs-target="#educationModal"><img src="images/plus.svg" alt="" /></a>
                                <a className="btns blue" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">Python Training</a> */}
                              
                         
                            
                        </div>

                    </div>
            </section>

            
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(MyTrainingPath);