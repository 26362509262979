import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'

import { connect, useSelector, useDispatch } from "react-redux"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"


// Redux Store
import { getLoginModalState, setLoginModalState, setSignupModalState, showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import { Container, Input, Label } from "reactstrap"
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
import 'bootstrap-select'; // Import Bootstrap Select JS
import { Modal, Dropdown, Form, FormControl, Button } from 'react-bootstrap'; // Import Bootstrap modal functionality



// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo-sm.png"
import logoLight from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"


// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//i18n
import { withTranslation } from "react-i18next"
// actions
import { loginUser, logoutUser, socialLogin } from "../../store/actions";
import { getCurrentUser, handleImageError, loadImageURL } from "helpers/functions"
import withRouter from "components/Common/withRouter"
import { SHOW_LOGIN_MODAL } from "store/auth/login/actionTypes"
import { API_BASE_URL, CHANGE_PASS, FORGOT_PASS_VERIFICATION_OTP, SEND_USER_VERIFICATION_OTP, UPDATE_USER_INFO, USER_SIGNUP, USER_VERIFICATION } from "helpers/url_helper"
import firebaseConfig from "helpers/config"
import { initFirebaseBackend } from "helpers/firebase_helper"
import Spinners from "components/Common/Spinner"
import { useFormik } from "formik"
import * as Yup from "yup";
import { post } from "helpers/api_helper"

const Header = props => {
  const [isSearch, setSearch] = useState(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

  const [emailLoading, setEmailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [signupModalShow, setSignupModalShow] = useState(false);
  const [seachModalShow, setSearchModalShow] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  
  
  const [changingPass, setChangingPass] = useState(false);

  const [signingUp, setSigningUp] = useState(false);
  const [verificationSending, setVerificationSending] = useState(false);
  const [verificationMode, setVerificationMode] = useState(false);
  
  const [forgotPassVerificationMode, setForgotPassVerificationMode] = useState(false);
  const [forgotPassView, setForgotPassView] = useState(false);
  const [forgotPassVerificationSending, setForgotPassVerificationSending] = useState(false);
  
  
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  // const [show, setShow] = useState(false);

  // const handleLoginClose = () => setLoginModalShow(false);
  // const handleLoginShow = () => setLoginModalShow(true);

  const responseHandler = (response) => {
    const { type, status, error, loading, modalState } = response

    console.log("R ",type, status, error, loading, modalState)

    if(type == "google") {
      setGoogleLoading(loading)
    } else if(type == "microsoft") {
      setMicrosoftLoading(loading)
    } else if(type == "apple") {
      setAppleLoading(loading)
    } else if(type == "email") {
      setEmailLoading(loading)
      // handleLoginClose();
    }

    
    setLoginError(error)

    dispatch(setLoginModalState(modalState))

  }

  const handleLoginClose = (res) => {

    // console.log("ReS ",res,getCurrentUser())

    setGoogleLoading(false)
    setMicrosoftLoading(false)
    setAppleLoading(false)

    setLoginError(null)

    hideForgotPassword()

    loginFormValidation.resetForm()

    dispatch(setLoginModalState(false))
  };
  const handleLoginShow = () => dispatch(setLoginModalState(true));

  // const handleSignupClose = () => setSignupModalShow(false);
  // const handleSignupShow = () => setSignupModalShow(true);
  const handleSignupClose = () => {
    validation.resetForm()
    hideForgotPassword()
    dispatch(setSignupModalState(false))
  };
  const handleSignupShow = () => dispatch(setSignupModalState(true));

  const handleSearchClose = () => setSearchModalShow(false);
  const handleSearchShow = () => setSearchModalShow(true);

  
  const history = useNavigate();
  
  const dispatch = useDispatch();


  // const location = useLocation()

  // useEffect(() => {
  // fetchData()
  // }, [location.key])

  // const fetchData = () => {
  //     console.log("FETCH ",searchParams.get("q"))
  // }

  useEffect(() => {

    // console.log("QC ",searchParams.get("q"))
    // Check if the 'editMode' parameter is present
    if (searchParams.get("q")) {

      setQuery(searchParams.get("q"))
      
        

    } else {
      setQuery("")
    }

    if (searchParams.get("c")) {

      setSelectedCategory(searchParams.get("c"))
        // setLoading(true)
        

    } else {
      setSelectedCategory("")
    }
}, [searchParams]);

  const getCategoryList = () => {
    const list = localStorage.getItem("categoryList");
    if (list) return JSON.parse(list);
    return [];
  };

  const initialize = () => {
    // const categoryListState = useSelector((state) => {
    //   console.log("S ",state?.Profile?.category_list || [])
  
    //   return state?.Profile?.category_list || []
    // })

    

    setCategoryList(getCategoryList())
  };


  const Logout = () => {
    

    dispatch(logoutUser(history));
    // useEffect(() => {
    // }, [dispatch, history]);

    // return <></>;
  };

  // useEffect to initialize the plugin after the component mounts
  // useEffect(() => {
  //   console.log("READY HEADER MAIN")
    

    

  //   // let user = getCurrentUser()
  //   // console.log("USER ",user)
  //   // setIsUserLoggedIn()
  //   // $('.selectpicker').selectpicker();

  //   // $('.selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
  //   //   // Handle the change event here
  //   // });
  // });
  useEffect(() => {
    // console.log("READY HEADER")
    initialize();

    

    // let user = getCurrentUser()
    // console.log("USER ",user)
    // setIsUserLoggedIn()
    // $('.selectpicker').selectpicker();

    // $('.selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    //   // Handle the change event here
    // });
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
    
  }

  const handleLoginClick = () => {
    // Use Bootstrap's modal method to show the modal
    // const loginModal = new Modal(document.getElementById('loginModal'));
    // loginModal.hide();
  };

  const tmpLogin = (val) => {
    dispatch(setLoginModalState(val))
    // let l = dispatch(getLoginModalState())
    // console.log(l)
    return
  }

  const signIn = async (type) => {

    if(type == "google") {
      setGoogleLoading(true)
    } else if(type == "microsoft") {
      setMicrosoftLoading(true)
    } else if(type == "apple") {
      setAppleLoading(true)
    }
    
    // dispatch(socialLogin(type, props.router.navigate));
    
    try {
      
      initFirebaseBackend(firebaseConfig)
      let res = await dispatch(socialLogin(type, responseHandler, props.router.navigate)); // Dispatch social login action
      console.log("SL ", type,res);
      // Once authentication is complete, you can disable the loader
      
      
    } catch (error) {

      if(type == "google") {
        setGoogleLoading(false)
      } else if(type == "microsoft") {
        setMicrosoftLoading(false)
      } else if(type == "apple") {
        setAppleLoading(false)
      }
      
      console.error("Error during authentication:", error);
      // setLoading(false); // In case of error, also disable the loader
    }
  };

  //for facebook and google authentication
  const socialResponse = type => {
    
    signIn(type);
  };

  const userLogin = () => {
   
    // if (!localStorage.getItem("authUser")) {
    // }
    let formData = {
      email: loginFormValidation.values.email || '',
      password: loginFormValidation.values.password || '',
    }
    dispatch(loginUser(formData, "email", responseHandler, props.router.navigate));
    
    // handleSignupClose(); 
  }

  const userSignup = () => {
    // if (!localStorage.getItem("authUser")) {
    // }
    let formData = {
      email: "admin@xpertnest.com" || '',
      password: "test" || '',
    }
    // dispatch(loginUser(formData, props.router.navigate));
    // handleLoginClose();
    handleSignupClose(); 
  }



  const handleInputChange = (event) => {
    
      setQuery(event.target.value);
  };

  const handleKeyPress = (event) => {

    if(query.trim() == "") {
      alert("Please entry something in searchbar to search courses");
      return
    }

    let categoryId = selectedCategory == null || selectedCategory == "" ? "" : selectedCategory

    // console.log("EVENT ",`/search-results?q=${query}&c=${selectedCategory}`)
      if (event.key === 'Enter') {
          navigate(`/search-results?q=${query}&c=${categoryId}`);

      }
  };

  const gotToCourseList = (e,c) => {
    e.preventDefault()
    // console.log("C ",c)

    // let categoryName = c.catName == null || c.catName == "" ? "" : c.catName
    let categoryId = c.catID == null || c.catID == "" ? "" : c.catID

    // console.log("EVENT ",`/search-results?q=${query}&c=${selectedCategory}`)
    navigate(`/course-list?q=${query}&c=${categoryId}`);
    
  };



  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email : "",
    // mobileNo : "",
    verificationCode : "",
    password : "",
    confirmPassword : "",
  })

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: verificationMode ? Yup.object({
      first_name: Yup.string().required("Please enter first name"),
      last_name: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      verificationCode: Yup.string()
          .min(6, 'OTP must be at least 8 characters')
          .required('Please enter OTP'),
      password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Please enter password'),
      confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please enter confirm password')
    })
    : 
    Yup.object({
      first_name: Yup.string().required("Please enter first name"),
      last_name: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Please enter password'),
      confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please enter confirm password')
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here

      try {


          setSigningUp(true);
            
            // console.log("SUBMITTED")
            //   setTimeout(() => {
            //     setLoading(false)
            //     setEditMode(false)
            //   },3000)

            

          post(USER_VERIFICATION, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
                let message = response?.response || "";
                let responseData = response?.data || [];

                console.log("RES  ",response)
                

                // console.log("E ",updatedObject,response)
                
                window.alert("Success!\n"+message)
                // window.history.go(-1);
                setVerificationMode(false)
                setSigningUp(false)
                handleSignupClose();
                handleLoginShow();
                

            }).catch((e) => {
                // console.log("RES UPDATE PROFILE ERR ",e)
                window.alert("Sorry!\n"+e)
                setSigningUp(false)
            }).finally(() => {
            });   

        } catch(e) {
          setSigningUp(false)
        }
      
      
     
    },
  });



  const [loginFormValues, setLoginFormValues] = useState({
    email : "",
    password : "",
  })


  const loginFormValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: loginFormValues,
    validationSchema:Yup.object({
      email: Yup.string().email().required("Please enter email"),
      password: Yup.string().required('Please enter password'),
    }),
  
    onSubmit: async (values) => {
      // Handle form submission logic here
      setEmailLoading(true)
      try {

            userLogin()

        } catch(e) {
          setEmailLoading(false)
        }
      
      
     
    },
  });


  const sendVerification = () => {


    post(SEND_USER_VERIFICATION_OTP, validation.values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                  
        let message = response?.response || "";
        let responseData = response?.data || [];

        console.log("RES  ",response)

        
        setVerificationMode(true)
        setVerificationSending(false)

        // console.log("E ",updatedObject,response)
        
        // window.alert("Success!\n"+message)
        // window.history.go(-1);
        

    }).catch((e) => {
        // console.log("RES UPDATE PROFILE ERR ",e)
        window.alert("Sorry!\n"+e)
        setVerificationSending(false)
    }).finally(() => {
    });   
  }
  
  const verificationEmailPrepare = () => {
    setVerificationSending(true)

    validation.validateForm().then(res => {
      // console.log("RED ",res,validation.errors)

      let hasError = false;

      Object.keys(res).forEach(field => {
        
        validation.setFieldTouched(field, true)
        hasError = true;

      });

      
      if(!hasError) {
        // setTimeout(() => {
        //   setVerificationMode(true)
        //   setVerificationSending(false)
        // }, 3000)

        sendVerification()

      } else {
        setVerificationSending(false)
      }

    })
    
    // console.log(validation.validateForm())

   
  }
  const loginProcess = () => {
    setEmailLoading(true)

    loginFormValidation.validateForm().then(res => {
      // console.log("RED ",res,validation.errors)

      let hasError = false;

      Object.keys(res).forEach(field => {
        
        loginFormValidation.setFieldTouched(field, true)
        hasError = true;

      });

      
      if(!hasError) {
        // setTimeout(() => {
        //   setVerificationMode(true)
        //   setVerificationSending(false)
        // }, 3000)

        userLogin()

      } else {
        setEmailLoading(false)
      }

    })
    
    // console.log(validation.validateForm())

   
  }


  // FORGOT PASSWORD METHODS


  const [forgotPassFormValues, setForgotPassFormValues] = useState({
    email : "",
    verificationCode : "",
    password : "",
    confirmPassword : "",
  })

  const forgotPassValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: forgotPassFormValues,
    validationSchema: forgotPassVerificationMode ? Yup.object({
      email: Yup.string().email().required("Please enter email"),
      verificationCode: Yup.string()
          .min(6, 'OTP must be at least 8 characters')
          .required('Please enter OTP'),
      password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Please enter password'),
      confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please enter confirm password')
    })
    : 
    Yup.object({
      email: Yup.string().email().required("Please enter email"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here

      try {


        setChangingPass(true);
            
         
            

          post(CHANGE_PASS, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                
                let message = response?.response || "";
                let responseData = response?.data || [];

                console.log("RES  ",response)
                

                // console.log("E ",updatedObject,response)
                
                window.alert("Success!\n"+message)
                // window.history.go(-1);
                setChangingPass(false)
                hideForgotPassword()
                

            }).catch((e) => {
                // console.log("RES UPDATE PROFILE ERR ",e)
                window.alert("Sorry!\n"+e)
                setChangingPass(false)
            }).finally(() => {
            });   

        } catch(e) {
          setChangingPass(false)
        }
      
      
     
    },
  });


  const sendForgotPasswordVerification = () => {


    post(FORGOT_PASS_VERIFICATION_OTP, forgotPassValidation.values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                  
        let message = response?.response || "";
        let responseData = response?.data || [];

        console.log("RES  ",response)

        
        setForgotPassVerificationMode(true)
        setForgotPassVerificationSending(false)


        // console.log("E ",updatedObject,response)
        
        // window.alert("Success!\n"+message)
        // window.history.go(-1);
        

    }).catch((e) => {
        // console.log("RES UPDATE PROFILE ERR ",e)
        window.alert("Sorry!\n"+e)
        setForgotPassVerificationSending(false)
    }).finally(() => {
    });   
  }


  const forgotVerificationEmailPrepare = () => {
    setForgotPassVerificationSending(true)

    forgotPassValidation.validateForm().then(res => {
      // console.log("RED ",res,validation.errors)

      let hasError = false;

      Object.keys(res).forEach(field => {
        
        forgotPassValidation.setFieldTouched(field, true)
        hasError = true;

      });

      
      if(!hasError) {
        // setTimeout(() => {
        //   setVerificationMode(true)
        //   setVerificationSending(false)
        // }, 3000)

        sendForgotPasswordVerification()

      } else {
        setForgotPassVerificationSending(false)
      }

    })
    
    // console.log(validation.validateForm())

   
  }


  const showForgotPassword = (e = null) => {
    if(e) {
      e.preventDefault()
    }

    setForgotPassVerificationMode(false)
    setForgotPassVerificationSending(false)
    forgotPassValidation.resetForm()
    setForgotPassView(true)
  }

  const hideForgotPassword = (e = null) => {
    if(e) {
      e.preventDefault()
    }

    setForgotPassVerificationMode(false)
    setForgotPassVerificationSending(false)
    forgotPassValidation.resetForm()
    setForgotPassView(false)
  }
  

  // END FORGOT PASSWORD METHODS




  const navigateTo = (e,url) => {
    e.preventDefault();
    handleSignupClose();
    handleLoginClose();
    navigate(url)
  }

  return (
    <React.Fragment>
      {/* <header className="header_sec">
        <div className="container">
          <nav className="navbar">
            <a className="navbar-brand" href="#"><img src="images/logo.svg" alt="" /></a>
            <div className="right">
              
              <div className="dropdown notifyWrapper">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="images/bell_ic.svg" alt="" />
                  <span className="blink"></span>
                </button>
                <div className="dropdown-menu">
                  <div className="notifyHeader">
                    <h4>Notification</h4>
                    <a href="#" className="read_link">Mark All as Read</a>
                  </div>
                  
                  <div className="notification_scroll">
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                    <div className="notify_item">
                      <a href="#" className="item_info">
                        <figure><img src="images/user.jpg" alt="" /></figure>
                        <div className="noti_msg">
                          <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              
              <div className="dropdown user_drop">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <figure className="m-0"><img src="images/user.jpg" alt="" /> <i><img src="images/user_ic.svg" alt="" /></i></figure>
                  <span>Jigar Trivedi</span>
                </button>
                <div className="dropdown-menu">
                  <div className="user_info">
                    <figure><img src="images/user.jpg" alt="" /> <a href="#"><img src="images/editprof_ic.svg" alt="" /></a></figure>
                    <strong>Jigar Trivedi</strong>
                    <span>jigar@vspplatform.com</span>
                  </div>
                  <ul>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic1.svg" alt="" /></i> My Profile</a></li>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic2.svg" alt="" /></i> Settings</a></li>
                    <li><a className="dropdown-item" href="#"><i><img src="images/user_ic3.svg" alt="" /></i> Help & Support</a></li>
                  </ul>
                  <a href="index.html" className="logout_btn"><img src="images/logou_ic.svg" alt="" /> Log out</a>
                </div>
              </div>
            </div>
            <div className="middle">
              <select className="selectpicker">
                <option selected>Explore Courses</option>
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
              </select>
              <button className="search_button d-flex d-md-none" data-bs-target="#searchModal" data-bs-toggle="modal"><img src="images/search_ic.svg" alt="" /></button>
              <div className="input-group d-none d-md-flex">
                <span className="input-group-text"><img src="images/search_ic.svg" alt="" /></span>
                <input type="text" className="form-control" placeholder="What do you want to learn?" />
              </div>
            </div>
            
          </nav>
        </div>
      </header> */}

      <header className="header_sec" >
        <div className="container">
          <nav className="navbar">
            <Link className="navbar-brand" to="/"><img src="/images/logo.svg" alt="" /></Link>
            <div className="right" >
              {/* <a href="#" onClick={handleLoginClick} data-bs-toggle="modal" data-bs-target="#loginModal" className="btns bdr"><img src="images/login_ic.svg" alt="" /> Login</a> */}
              
              {/* <button type="button" onClick={() => tmpLogin(!props.showLoginModal) } className="btns bdr"><img src="/images/login_ic.svg" alt="" /> Login -{props.showLoginModal ? "OPENED" : "FALSE"}</button> */}
              
              {getCurrentUser() == null ? <><button type="button" onClick={handleLoginShow} className="btns bdr"><img src="/images/login_ic.svg" alt="" /> Login</button>
              {/* <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#signModal" className="btns blue">Sign Up</a> */}
              
              {/* COMMENTED BECAUSE WE USE LOGIN ONLY FOR SIGNUP */}
              {/* <button type="button" onClick={handleSignupShow} className="btns blue">Sign Up</button> */}
              
              </>
              :
              <>
                  {/* <!-- Notification --> */}
                  {/* <div className="dropdown notifyWrapper">
                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="images/bell_ic.svg" alt="" />
                      <span className="blink"></span>
                    </button>
                    <div className="dropdown-menu">
                      <div className="notifyHeader">
                        <h4>Notification</h4>
                        <a href="#" className="read_link">Mark All as Read</a>
                      </div>
                      
                      <div className="notification_scroll">
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                        <div className="notify_item">
                          <a href="#" className="item_info">
                            <figure><img src="images/user.jpg" alt="" /></figure>
                            <div className="noti_msg">
                              <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing.</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <Dropdown className="dropdown user_drop">
                    <Dropdown.Toggle className="dropdown-toggle" variant="transparent" id="userDropdown">
                      <figure className="m-0">
                        {/* <img src="/images/user.jpg" alt="" /> */}
                        {/* <img onError={handleImageError} src={getCurrentUser().provider == 'google.com' ? getCurrentUser().profile_picture : API_BASE_URL+getCurrentUser()?.profile_picture || ""} alt="" /> */}
                        <img onError={handleImageError} src={loadImageURL(getCurrentUser()?.profile_picture || "")} alt="" />
                        <i>
                          <img src="/images/user_ic.svg" alt="" />
                        </i>
                      </figure>
                      <span>{getCurrentUser()?.first_name || "-"} {getCurrentUser()?.last_name || ""}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="">
                      <div className="user_info">
                        <figure>
                          {/* <img src="/images/user.jpg" alt="" />
                          <a href="#">
                            <img src="/images/editprof_ic.svg" alt="" />
                          </a> */}
                          <img onError={handleImageError} src={loadImageURL(getCurrentUser()?.profile_picture || "")} alt="" />
                        </figure>
                        <strong>{getCurrentUser()?.first_name || "-"} {getCurrentUser()?.last_name || ""}</strong>
                        <br />
                        <span>{getCurrentUser()?.email || "-"}</span>
                      </div>

                      <ul>
                        <li>
                        {/* <Dropdown.Item href="/aboutme/my-profile"> */}
                        <Dropdown.Item href="/dashboard">
                          <i>
                            <img src="/images/user_ic1.svg" alt="" />
                          </i>{' '}
                          My Profile
                        </Dropdown.Item>
                        </li>
                      
                        {/* <li>
                          <Dropdown.Item href="#">
                            <i>
                              <img src="/images/user_ic2.svg" alt="" />
                            </i>{' '}
                            Settings
                          </Dropdown.Item>
                        </li> */}
                        {/* <li>
                            <Dropdown.Item href="#">
                              <i>
                                <img src="/images/user_ic3.svg" alt="" />
                              </i>{' '}
                              Help & Support
                            </Dropdown.Item>
                        </li> */}
                      </ul>
                      {/* <Link to="/logout" className="logout_btn"><img src="/images/logou_ic.svg" alt="" /> Log out</Link> */}
                      <Button type="button" onClick={(e) => {
                        e.preventDefault();
                        Logout()
                      }} className="logout_btn"><img src="/images/logou_ic.svg" alt="" /> Log out</Button>
                    </Dropdown.Menu>
                  </Dropdown>

              </>
            }
            </div>
            <div className="middle">
              {/* <select className="selectpicker"> */}
              <Form.Select className="" onChange={(e) => {
                console.log("E",e.target.value)
                setSelectedCategory(e.target.value)
                if(e.target.value == "") {
                  const elementToScrollTo = document.getElementById("category_section");
                  if (elementToScrollTo) {
                    elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
                  }
                } else {
                  gotToCourseList(e,{ catID : e.target.value || null})
                }
              }} value={selectedCategory} placeholder="Explore" style={{ width : '35%' }}>
                <option value="" disabled selected hidden>Explore</option>
                <optgroup label="Goals">
                  <option value={""}>Explore a new course</option>
                  <option disabled value={"SILENT_1_2"}>Find your new career (Coming up..)</option>
                  <option disabled value={"SILEINT_1_3"}>Earn a certificate (Coming up..)</option>
                </optgroup>
                <optgroup label="Categories">
                  {categoryList.length > 0 && categoryList.map((category,index) => {
                      return(<option value={category.category_id}>{category.category_name}</option>)
                    })
                  }
                </optgroup>
                {/* <option value={null}>Explore Courses</option>
                <option value={2}>One</option>
                <option value={3}>Two</option>
                <option value={4}>Three</option> */}
              </Form.Select>

              {/* </select> */}
              <button className="search_button d-flex d-md-none" data-bs-target="#searchModal" data-bs-toggle="modal"><img src="/images/search_ic.svg" alt="" /></button>
              <div className="input-group d-none d-md-flex">
                <span className="input-group-text"><img src="images/search_ic.svg" alt="" /></span>
                <input type="text" value={query}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyPress} className="form-control" placeholder="What do you want to learn?" />
              </div>
            </div>
          </nav>
        </div>
      </header>

      
      {/* <!-- Search Modal --> */}
        {/* <div className="modal fade search_modal" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body"> */}
              <Modal show={seachModalShow} className="fade search_modal"  backdrop={true} backdropClassName="fade" onHide={handleSearchClose} centered>
                <Modal.Body className="modal-body">
                  <button type="button" className="btn-close btn-close-white" onClick={handleSearchClose}></button>
                  <div className="input-group">
                    <input type="text" value={query}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyPress}
                    className="form-control" placeholder="What do you want to learn?" />
                    <button><img src="/images/search_ic.svg" alt="" /></button>
                  </div>
                  </Modal.Body>
                </Modal>
              {/* </div>
            </div>
          </div>
        </div> */}


        
      {/* <!-- Login Modal --> */}
      {/* <div className="modal fade login_modal" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body"> */}
            <Modal show={props.showLoginModal} className="fade login_modal"  backdrop={true} backdropClassName="fade" onHide={() => handleLoginClose()} centered>
              <Modal.Body className="modal-body">
              <button type="button" className="btn-close"  onClick={() => handleLoginClose()}></button>
              { forgotPassView ? <h2 className="global_title mb-0">Reset password</h2> : <h2 className="global_title mb-0">Login to your account</h2>}
              {/* <p className="info"></p> */}
              <p className="login-error text-danger">{loginError}</p>
              

              {/* <Form className="needs-validation mt-30 mb-10"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>  */}

                { !forgotPassView ? <div className="row mt-10">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input_group custom-signup-input-group">
                  
                          <Label>Email</Label>
                          <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                disabled={verificationMode}
                                onChange={loginFormValidation.handleChange}
                                onBlur={loginFormValidation.handleBlur}
                                value={loginFormValidation.values.email || ''}
                                invalid={loginFormValidation.touched.email && loginFormValidation.errors.email ? true : false}
                            />
                            {loginFormValidation.touched.email && loginFormValidation.errors.email ? (
                                <div className="invalid-feedback">{loginFormValidation.errors.email}</div>
                            ) : null}
                      </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input_group custom-signup-input-group">
                  
                          <Label>Password</Label>
                          <Input
                                name="password"
                                placeholder="Password"
                                type="password"
                                disabled={verificationMode}
                                onChange={loginFormValidation.handleChange}
                                onBlur={loginFormValidation.handleBlur}
                                value={loginFormValidation.values.password || ''}
                                invalid={loginFormValidation.touched.password && loginFormValidation.errors.password ? true : false}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    loginProcess();
                                  }
                                }}
                            />
                            {loginFormValidation.touched.password && loginFormValidation.errors.password ? (
                                <div className="invalid-feedback">{loginFormValidation.errors.password}</div>
                            ) : null}
                      <p style={{ textAlign : 'right', marginTop: 10 }}><a  onClick={(e) => showForgotPassword(e)}>Forgot password?</a></p>
                      </div>
                  </div>
                </div>
                :
                  <Form className="needs-validation mt-30 mb-10"  onSubmit={(e) => {
                    e.preventDefault();
                    forgotPassValidation.handleSubmit();
                    return false;
                  }}> 
    
                    
                  { !forgotPassVerificationMode && <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input_group custom-signup-input-group">
                  
                          <Label>Email</Label>
                          <Input
                                name="email"
                                placeholder="Enter email"
                                type="email"
                                disabled={forgotPassVerificationMode}
                                onChange={forgotPassValidation.handleChange}
                                onBlur={forgotPassValidation.handleBlur}
                                value={forgotPassValidation.values.email || ''}
                                invalid={forgotPassValidation.touched.email && forgotPassValidation.errors.email ? true : false}
                            />
                            {forgotPassValidation.touched.email && forgotPassValidation.errors.email ? (
                                <div className="invalid-feedback">{forgotPassValidation.errors.email}</div>
                            ) : null}
                            <p style={{ textAlign : 'right', marginTop: 10 }}><a  onClick={(e) => hideForgotPassword(e)}>Go back to Login</a></p>
                      </div>
                  </div>}
    
                  { forgotPassVerificationMode  && <><div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input_group custom-signup-input-group">
    
                          <Label htmlFor="validationCustom01">Verification Code</Label>
                          <Input
                              name="verificationCode"
                              placeholder="Enter verification code"
                              type="text"
                              onChange={forgotPassValidation.handleChange}
                              onBlur={forgotPassValidation.handleBlur}
                              value={forgotPassValidation.values.verificationCode || ''}
                              invalid={forgotPassValidation.touched.verificationCode && forgotPassValidation.errors.verificationCode ? true : false}
                          />
                          {forgotPassValidation.touched.verificationCode && forgotPassValidation.errors.verificationCode ? (
                              <div className="invalid-feedback">{forgotPassValidation.errors.verificationCode}</div>
                          ) : null}
                          
                      </div>
                  </div>
                  
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input_group custom-signup-input-group">
                  
                          <Label>New Password</Label>
                          <Input
                                name="password"
                                placeholder="Create new password"
                                type="password"
                                onChange={forgotPassValidation.handleChange}
                                onBlur={forgotPassValidation.handleBlur}
                                value={forgotPassValidation.values.password || ''}
                                invalid={forgotPassValidation.touched.password && forgotPassValidation.errors.password ? true : false}
                            />
                            {forgotPassValidation.touched.password && forgotPassValidation.errors.password ? (
                                <div className="invalid-feedback">{forgotPassValidation.errors.password}</div>
                            ) : null}
                      </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-40">
                      <div className="input_group custom-signup-input-group">
                  
                          <Label>Confirm New Password</Label>
                          <Input
                                name="confirmPassword"
                                placeholder="Re-enter new password"
                                type="password"
                                onChange={forgotPassValidation.handleChange}
                                onBlur={forgotPassValidation.handleBlur}
                                value={forgotPassValidation.values.confirmPassword || ''}
                                invalid={forgotPassValidation.touched.confirmPassword && forgotPassValidation.errors.confirmPassword ? true : false}
                            />
                            {forgotPassValidation.touched.confirmPassword && forgotPassValidation.errors.confirmPassword ? (
                                <div className="invalid-feedback">{forgotPassValidation.errors.confirmPassword}</div>
                            ) : null}
                      </div>
                  </div></>
                  }
    
                  
                
                { !forgotPassView && <p className="terms_info custom-terms_inf">By continuing, you agree to VSP Company’s <a href="" onClick={(e) => navigateTo(e,"/termscondition")}>Terms of Use</a> and <a href="#" onClick={(e) => navigateTo(e,"/privacypolicy")}>Privacy Policy</a>.</p>}
                
                { !forgotPassVerificationMode ?
                  <button type="button"
                  onClick={() => {
                      forgotVerificationEmailPrepare();
                  }}
                  className="btns bdr w-100 force-w100" >{forgotPassVerificationSending ? <Spinners  /> : 'Send verification email'}</button>
                  :
                  <button type="submit"
                    className="btns bdr w-100 force-w100" >{ changingPass ? <Spinners  /> :  'Change Password' }</button>
                }
                </Form>
                }
                  {/* </Form> */}
              
              { !forgotPassView && <>
                    <button type="button" onClick={() => loginProcess()}
                      className="btns blue mt-20 mb-10" style={{ width : '100%', maxWidth : '100%'}}>{ emailLoading ? <Spinners  /> : "Login"}</button>

                      <span style={{ color: '#aca9a9', width : '100%' }}>OR</span>
                      
                      <button type="button" onClick={(e) => { socialResponse("google")  }} className="loginwith_btn custom-loginwith_btn mt-10"  style={{ maxWidth : '100%'}} >{ googleLoading ? <Spinners  /> : <><img src="/images/google-icon22x.svg" alt="" /><span>Login with Google</span></>}</button>


                      {/* <button type="button" onClick={() => userLogin()} className="loginwith_btn"><img src="/images/email_ic.svg" alt="" /> { microsoftLoading ? <div className="container text-center"><Spinners  /></div> : "Login with Microsoft" }</button> */}
                      {/* <button type="button" onClick={() => socialResponse("apple")} className="loginwith_btn"><img src="/images/apple-icon20x.svg" alt="" /> { appleLoading ? <div className="container text-center"><Spinners  /></div> : "Login with Apple" }</button> */}
                      
                      
                      <p className="terms_info">By continuing, you agree to VSP Company’s <a href="" onClick={(e) => navigateTo(e,"/termscondition")}>Terms of Use</a> and <a href="#" onClick={(e) => navigateTo(e,"/privacypolicy")}>Privacy Policy</a>.</p>
                      
                      <p><a href="#" className="btn_link">Don’t have an account?</a></p>
                      <button type="button" onClick={() => {
                        handleLoginClose();
                        handleSignupShow();
                      }}
                      className="btns w-100">Sign Up</button>
                </>}
              </Modal.Body>
            </Modal>
            {/* </div>
          </div>
        </div>
      </div> */}

      {/* <!-- Signup Modal --> */}
      {/* <div className="modal fade login_modal signup_modal" id="signModal" tabIndex="-1" aria-labelledby="signModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body"> */}
            <Modal show={props.showSignupModal} className="fade login_modal signup_modal"  backdrop={true} backdropClassName="fade" onHide={handleSignupClose} centered>
              <Modal.Body className="modal-body">
              <button type="button" className="btn-close" onClick={handleSignupClose}></button>
              <h2 className="global_title mb-0">Create an account</h2>
            
              <Form className="needs-validation mt-30 mb-10"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}> 

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="input_group custom-signup-input-group">

                          <Label htmlFor="validationCustom01">First Name</Label>
                          <Input
                              name="first_name"
                              placeholder="Enter first name"
                              type="text"
                              disabled={verificationMode}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ''}
                              invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                              <div className="invalid-feedback">{validation.errors.first_name}</div>
                          ) : null}
                          
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="input_group custom-signup-input-group">

                          <Label htmlFor="validationCustom01">Last Name</Label>
                          <Input
                              name="last_name"
                              placeholder="Enter last name"
                              type="text"
                              disabled={verificationMode}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ''}
                              invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                              <div className="invalid-feedback">{validation.errors.last_name}</div>
                          ) : null}
                          
                      </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input_group custom-signup-input-group">
                
                        <Label>Email</Label>
                        <Input
                              name="email"
                              placeholder="Enter email"
                              type="email"
                              disabled={verificationMode}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                              <div className="invalid-feedback">{validation.errors.email}</div>
                          ) : null}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input_group custom-signup-input-group">
                
                        <Label>Password</Label>
                        <Input
                              name="password"
                              placeholder="Create password"
                              type="password"
                              disabled={verificationMode}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                              <div className="invalid-feedback">{validation.errors.password}</div>
                          ) : null}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input_group custom-signup-input-group">
                
                        <Label>Confirm Password</Label>
                        <Input
                              name="confirmPassword"
                              placeholder="Re-enter password"
                              type="password"
                              disabled={verificationMode}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ''}
                              invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                          />
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                              <div className="invalid-feedback">{validation.errors.confirmPassword}</div>
                          ) : null}
                    </div>
                </div>

                { verificationMode && <div className="col-lg-12 col-md-12 col-sm-12 mb-40">
                    <div className="input_group custom-signup-input-group">

                        <Label htmlFor="validationCustom01">Verification Code</Label>
                        <Input
                            name="verificationCode"
                            placeholder="Enter verification code"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.verificationCode || ''}
                            invalid={validation.touched.verificationCode && validation.errors.verificationCode ? true : false}
                        />
                        {validation.touched.verificationCode && validation.errors.verificationCode ? (
                            <div className="invalid-feedback">{validation.errors.verificationCode}</div>
                        ) : null}
                        
                    </div>
                </div>}
              
              
              <p className="terms_info custom-terms_inf">By continuing, you agree to VSP Company’s <a href="" onClick={(e) => navigateTo(e,"/termscondition")}>Terms of Use</a> and <a href="#" onClick={(e) => navigateTo(e,"/privacypolicy")}>Privacy Policy</a>.</p>
              
              {verificationSending ? <Spinners  /> : !verificationMode ?
                <button type="button"
                onClick={() => {
                    verificationEmailPrepare();
                }}
                className="btns bdr w-100">Send verification email</button>
                :
                <button type="submit"
                  className="btns bdr w-100">{ verificationSending ? <Spinners  /> :  'Sign Up' }</button>
              }
              </Form>
              <button type="button" onClick={() => {
                  handleSignupClose();
                  handleLoginShow();
              }}
              className="btns w-100 ">Already have an account?</button>
              {/* <p><a href="#" className="btn_link mt-10">Already have an account?</a></p> */}
              {/* <a href="after_login.html" className="btns bdr w-100"><img src="images/lock_icon.svg" alt="" /> Login</a> */}
              </Modal.Body>
            </Modal>
            {/* </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  getLoginModalState: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, showLoginModal, showSignupModal } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, showLoginModal, showSignupModal }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  getLoginModalState
})(withRouter(withTranslation()(Header)))
