import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS

import sampleData from "./sample.json"
import additional from "./additional.json"



import Header from "./Header";
import Footer from "./Footer";

const RecommendedJobs = (props) => {
  document.title = "Recommended Jobs - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [isLoadMoreLoading, setLoadMoreLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [offset, setOffset] = useState(10)
  const [loadMoreCount, setLoadMoreCount] = useState(50)
  const [skipCount, setSkipCount] = useState(1)
  const [jobList, setJobList] = useState([])
  const [jobListMaster, setJobListMaster] = useState([])
  const [searchQuery, setSearchQuery] = useState("");

  const [formValues, setFormValues] = useState({
    search: '',
  })

  const [visibleJobs, setVisibleJobs] = useState(5);

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: formValues,
    validationSchema: Yup.object({
        search: Yup.string().required("Please enter search query"),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      setLoading(true);
      
      console.log("SUBMITTED")
      setTimeout(() => {
        setLoading(false)
        setEditMode(false)
      },3000)
    //   post(url.UPDATE_BADGE, values, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

         
    //     let message = response?.response || "";
    //     let responseData = response?.data || [];
        
    //     window.alert("Success!\n"+message)
    //     window.history.go(-1);
    //     setLoading(false)

    //   }).catch((e) => {
    //     window.alert("Sorry!\n"+e)
    //   }).finally(() => {
    //     setLoading(false)
    //   });   
      
     
    },
  });

  
  useEffect(() => {
    
  })

  const fetchData = (reset=false,isClear=false) => {
    
    if(reset) {
      setLoading(true)
    } 

    if(!reset) {
      setLoadMoreLoading(true)
    } 


    let skipC = skipCount
    let query = searchQuery

    if(reset) {
      skipC = 1;
    }

    if(isClear) {
      query = ""
    }

    const params = {
      resultsToTake: offset,
      resultsToSkip: (skipC - 1) * offset
    }
    
 
    // `keywords=${searchQuery}&resultsToTake=${offset}&resultsToSkip=${skip}`
    post(url.GET_RECOMMENED_REED_JOBS, params).then((response) => {

      // console.log("RES ",response)
        

        let jobs = response.data.results;
        let totalResults = response.data.totalResults;

        if(reset) {
          setJobList((prevData) => [...jobs])
        } else {
          setJobList((prevData) => [...prevData, ...jobs])
        }
        
        setSkipCount(prevPage => prevPage + 1);


        // console.log("J ",jobs)
        
        setLoading(false)
        setLoadMoreLoading(false)
      }).catch((e) => {
        window.alert("Sorry!\n"+e)
        setLoading(false)
        setLoadMoreLoading(false)
    }).finally(() => {
    });   


  }


  useEffect(() => {
    
    
    fetchData(true);

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 

  const loadMore = () => {
      fetchData()
  }


  // const handleInputChange = (event) => {  
  //   setSearchQuery(event.target.value);
  // };

// const handleKeyPress = (event) => {

//   if(searchQuery.trim() == "") {
//     // alert("Please entry something to search jobs");
//     return
//   }
//   if (event.key === 'Enter') {
//       executeSearch()
//   }


//   // const query = searchQuery.toLowerCase();
  

//   };

  // const executeSearch = () => {
    
  //   if(searchQuery.trim() === '' && jobList.length == 0) { 
  //     // setSkipCount(1)
  //     // // setJobListMaster(jobList)
  //     // fetchData()
  //     clear()
  //   } else if (searchQuery.trim() === '') {

  //   } else {
  //     setSkipCount(1)
  //     // setJobListMaster(jobList)
  //     fetchData(true)
  //   }
  // }

  // const clear = () => {
  //   setSearchQuery("")
  //   setSkipCount(1)
  //   fetchData(true,true)
  // }

  return (
    <React.Fragment>
    {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
    { 
      <>
                    
                
                <Header />


            <section className="about_sec">
                <Form className="needs-validation"  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}> 
                    <div className="container">
                      
                        
                    {/* <!-- Experience --> */}
                    <div className="about_main">
                      <ul className="breadcrumb">
                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                        <li><a href="#" title="My Jobs">My Jobs</a></li>
                        <li>Recommended Jobs</li>
                      </ul>

                      <div className="job_info">
                     
                      <h3>Job Match</h3>
                      <p>Recommendation based on your profile</p>

                        { isLoading ? 
                        <div className="text-center"><Spinners  /></div>
                        :
                        jobList.length > 0 ? jobList.map((job,i) => {

                            return(
                              <div className="job_block">
                                <div className="job_item">
                                  {/* <div className="icon"><img src="/images/job_logo_1.png" alt="" /></div> */}
                                  <div className="detail">
                                    <h5><small style={{ marginBottom : 5 }}>{job.employerName}</small>{job.jobTitle}</h5>
                                    <p className="clamp-text-2" style={{ marginTop : 10 }}>{job.jobDescription}</p>
                                  </div>
                                </div>
                                <div className="btn_info">
                                  <Link to={`/aboutme/job/${job.jobId}`} className="btns gray">View Job</Link>
                                  {/* <a href="#" className="btns gray">View Job</a> */}
                                  {/* <a href={job?.jobUrl || "#"} target="_blank" className="btns blue">Apply Now</a> */}
                                </div>
                              </div>
                            )
                        }) 
                        :
                        <div className="text-center">
                          <p>No jobs found</p>
                        </div>
                        }

                        
                      </div>			
                      <div className="text-center">
                        { !isLoading && jobList.length > 0 && <button type="button" onClick={() => loadMore()} className="btns blue">{ isLoadMoreLoading ? <Spinners  /> : 'Load More'}</button>}
                      </div>
                    </div>

                    </div>
                </Form>
            </section>

            <Footer />
                
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(RecommendedJobs);