// Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyDkuHU2SG0Y25QchE8Cgs-SJKFkov_50xQ",

  authDomain: "virtualskillsplatform-bae61.firebaseapp.com",

  projectId: "virtualskillsplatform-bae61",

  storageBucket: "virtualskillsplatform-bae61.appspot.com",

  messagingSenderId: "821095515079",

  appId: "1:821095515079:web:8748c60280ef857b3e4fe2",

  measurementId: "G-RX4FHRFLJY"

};


// apiKey: "AIzaSyDXdT8bjj669rYhsIIApC_zN5IvU57GkRk",

// authDomain: "bcp-vsp-test.firebaseapp.com",

// projectId: "bcp-vsp-test",

// storageBucket: "bcp-vsp-test.appspot.com",

// messagingSenderId: "475616039820",

// appId: "1:475616039820:web:cfdacd67458f57a26dfca9",



export default firebaseConfig;
// Initialize Firebase

// const app = initializeApp(firebaseConfig);