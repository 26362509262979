import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
// import { useHistory } from 'history';
import { calculateDiscount, formatOption, getCurrentUser, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS
import { useLocation, useParams } from "react-router-dom";



import Header from "./Header";
import Footer from "./Footer";




const SearchJobDetail = (props) => {
  document.title = "Job Detail - Virtual Skills Platform";

  const animatedComponents = makeAnimated();
 

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [saveJobLoading, setSavejobLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [job, setJob] = useState(null)
  const [allJobsURL, setAllJobsURL] = useState('/aboutme/jobs')

  const { id } = useParams(); 

  const [formValues, setFormValues] = useState({
    search: '',
  })

  const redirectToJobPage = () => {
    // Redirect to a new page
    // window.location.href = '/aboutme/jobs';
    window.location.href = allJobsURL;
  };



  useEffect(() => {
    
    const urlPath = window.location.href;
    console.log("URL ",url)
    // const urlPath = new URL(url).pathname;
    
    if (urlPath.includes('/aboutme/saved-job/')) {
      setAllJobsURL("/jobs/saved-jobs")
    } else if (urlPath.includes('/aboutme/job/')) {
      console.log("It's a job URL");
      setAllJobsURL("/aboutme/jobs")
    } else {
      // console.log("URL doesn't match expected patterns");
    }

    

    // Check if the 'editMode' parameter is present
    if (id) {
      setLoading(true)

      async function fetchExsitingData() {

        const params = {
          job_id: id,
        }

        let userInfo = getCurrentUser();

        if(userInfo != null) {
          // console.log("USER INFO ",userInfo)
          params.user_id = userInfo.user_id
        }

        post(url.GET_REED_JOB_DETAIL, params, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

          
          let responseData = response?.data || {};
          console.log("FETCHED ", responseData)

          setJob(responseData)

          // document.title = responseData?.jobTitle || "Search Job Detail - Virtual Skills Platform";
          setLoading(false)

        }).catch((e) => {
          window.alert("Sorry!\n" + e)
          setLoading(false)
        }).finally(() => {
        });

      }
      fetchExsitingData();


      
    } else {
      setLoading(false)
      window.history.go(-1);
    }
  }, [id]);


  const saveJob = (jobData,isSaved) => {
    
    setSavejobLoading(true)

    jobData.job_id = id

    post(url.SAVE_REED_JOB_DETAIL, jobData, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

          
      let responseData = response?.data || false;
      console.log("FETCHED ", responseData)

      let currentJob = job;

      currentJob.isJobSaved = responseData

      setJob(currentJob);

      // document.title = responseData?.jobTitle || "Search Job Detail - Virtual Skills Platform";
      setSavejobLoading(false)

    }).catch((e) => {
      window.alert("Sorry!\n" + e)
      setSavejobLoading(false)
    }).finally(() => {
    });
  }

  return (
    <React.Fragment>
    { 
      isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  /></div></section></> :
      <>
                    
                
                <Header />


            <section className="about_sec">
            
                    <div className="container">
                      
                        
                    {/* <!-- Experience --> */}
                    <div className="about_main">
                      <ul className="breadcrumb">
                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                        <li><a href="#" title="About Me">About Me</a></li>
                        <li>Job Detail</li>
                      </ul>
                      
                      <div className="w-100">
                        <button type="button" onClick={redirectToJobPage} className="btns gray"><img src="/images/btn_arrow.svg" alt="" /> All Jobs</button>
                      </div>

                      <div className="job_detail">
                        <div className="row">
                          <div className="col-xl-9 col-lg-8 col-md-7">
                            <div className="detail">
                              <h2><small>Job Details</small> {job?.jobTitle || "-"}</h2>
                              <div className="custom-job-description" dangerouslySetInnerHTML={{ __html : job?.jobDescription}}>
                              </div>
                              {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
                              <h6>Qualification Include</h6>
                              <ul className="job_list">
                                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.</li>
                                <li>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur eleifend.</li>
                                <li>Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</li>
                              </ul>
                              <h6>Benefits</h6>
                              <p>Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,</p> */}
                              {/* <a href={job?.jobUrl || "#"} target="_blank" className="btns blue">Apply Now</a> */}
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-4 col-md-5">
                            <div className="detail_side">
                              <div className="top">
                                {/* <figure><img src="/images/job_logo_1.png" alt="" /></figure> */}
                                <h3>{job?.employerName || "-"}</h3>
                                <p>{job?.jobTitle || ""}</p>
                                <a href={job?.jobUrl || "#"} target="_blank" className="btns blue">Apply Now</a>
                                {getCurrentUser() != null && <button onClick={() => saveJob(job,!job.isJobSaved)} className="btns blue ic-btn">{ saveJobLoading ? <Spinners  /> : <><img src={job.isJobSaved ? "/images/bookmark_filled.png" : "/images/bookmark_unfilled.png"} style={{ height : 30, paddingTop : 5, paddingBottom : 5, paddingRight : 5, paddingLeft: 5,   }} alt="" /></>}</button>}
                              </div>

                              <div className="input_group">
                                <label for="text" className="form-label">Salary</label>
                                <h5>{job?.salary || "-" }</h5>
                              </div>

                              <div className="input_group">
                                <label for="text" className="form-label">Job Type</label>
                                <h5>{ job.fullTime ? "Full-time" : "Part-Time"}</h5>
                              </div>

                              <div className="input_group">
                                <label for="text" className="form-label">Contract Type</label>
                                <h5>{ job?.contractType || "-"}</h5>
                              </div>

                              <div className="input_group">
                                <label for="text" className="form-label">Location</label>
                                <h5>{job?.locationName || "-"}</h5>
                              </div>

                              <div className="input_group">
                                <label for="text" className="form-label">Date Posted</label>
                                <h5>{job?.datePosted || "-"}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    </div>
            </section>

            <Footer />
                
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(SearchJobDetail);